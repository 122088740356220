"use client";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateShoppingListInput } from "@/documents/__generated__/globalTypes.codegen";
import { SessionUser } from "@/hooks/user/utils";
import { FC, useEffect } from "react";
import tailwindConfig from "../../../../../tailwind.config";
import resolveConfig from "tailwindcss/resolveConfig";
import { useBoolean } from "@chakra-ui/icons";

const fullConfig = resolveConfig(tailwindConfig);

const colors: string[] = Object.values(fullConfig.theme.colors.list);

type SimpleCreateFormProps = {
  onCancel: () => void;
  user: SessionUser;
  onSubmit: SubmitHandler<CreateShoppingListInput>;
};

const SimpleCreateForm: FC<SimpleCreateFormProps> = ({
  user,
  onSubmit,
  onCancel,
}) => {
  const [isClient, { on }] = useBoolean();
  // TODO(@oleksii.a): investigate hydration warning
  useEffect(() => {
    on();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm<CreateShoppingListInput>({
    defaultValues: {
      color: colors[0],
      restaurantId: user.restaurantID,
    },
  });
  if (!isClient) {
    return null;
  }
  return (
    <Card aria-label={"New list form"} size="sm">
      <CardBody>
        <FormControl>
          <FormLabel autoFocus>New list name</FormLabel>
          <Input
            autoComplete="off"
            size="sm"
            {...register("name", {
              required: true,
            })}
          />
        </FormControl>
      </CardBody>
      <CardFooter>
        <ButtonGroup size="sm">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            aria-label={"Create and add item"}
            isDisabled={!isDirty}
            colorScheme="midnight_navy"
            isLoading={isSubmitting}
            onClick={(e) => {
              e.stopPropagation();
              return handleSubmit(onSubmit)(e);
            }}
          >
            Create and add item
          </Button>
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};

export default SimpleCreateForm;
