"use client";
import { useCallback, useMemo } from "react";
import {
  CellEventMetadata,
  EventName,
  PickerEventMetadata,
  ProductCellEventProperties,
  ProductEventProperties,
  ProductPickerEventProperties,
} from "@/hooks/analytics/types";
import { Product } from "@/hooks/products/utils";
import { PackageTypeEnum } from "@/documents/__generated__/globalTypes.codegen";
import { getDollarsPrice } from "@/utils/price";
import { EventAction, EventObject } from "@/app/lib/analytics/types";
import useAnalytics from "@/hooks/analytics";

const createProductEventProperties = (
  product: Product,
): ProductEventProperties => {
  const unitVariant = product.getVariantByPackageType(PackageTypeEnum.Unit);
  const caseVariant = product.getVariantByPackageType(PackageTypeEnum.Case);
  return {
    product_id: product.id,
    sku: product.sku,
    full_name: product.fullName,
    brand: product.brand,
    name: product.catalogName,
    discontinued: product.discontinued,
    replaceable: product.replaceable,
    out_of_stock: !product.inStock,
    department: product.mainCategory?.name,
    sub_department: product.subCategory?.name,
    shopping_list_product: product.isFavourite,
    description: product.description,
    jit: product.jit,
    atp: product.atp,
    in_stock_date: product.nextReceiveDate,
    volume_discount_available: product.variants.some(
      (v) => v.volumeDiscountedPriceLevel,
    ),
    ...(caseVariant && {
      case_quantity: caseVariant.quantity,
      case_price: getDollarsPrice(caseVariant.price),
      case_discounted_price: getDollarsPrice(
        caseVariant.baseVariantPrice?.price,
      ),
      ...(caseVariant.baseVariantPrice?.catchweightPrice && {
        case_catchweight_price: getDollarsPrice(
          caseVariant.baseVariantPrice?.catchweightPrice,
        ),
      }),
    }),
    ...(unitVariant && {
      unit_quantity: unitVariant.quantity,
      unit_price: getDollarsPrice(unitVariant.price),
      unit_discounted_price: getDollarsPrice(
        unitVariant.baseVariantPrice?.price,
      ),
      ...(unitVariant.baseVariantPrice?.catchweightPrice && {
        unit_catchweight_price: getDollarsPrice(
          unitVariant.baseVariantPrice?.catchweightPrice,
        ),
      }),
    }),
  };
};

export const useProductEvent = (product: Product) => {
  const { track } = useAnalytics();
  const productEventProperties = useMemo(
    () => createProductEventProperties(product),
    [product],
  );
  const trackProduct = useCallback(
    (action: EventAction) => {
      const eventName: EventName = `${EventObject.PRODUCT} ${action}`;

      return track(eventName, productEventProperties);
    },
    [track, productEventProperties],
  );
  const trackProductCell = useCallback(
    (action: EventAction, cellMetadata: CellEventMetadata) => {
      const eventName: EventName = `${EventObject.PRODUCT_CELL} ${action}`;
      const props: ProductCellEventProperties = {
        ...productEventProperties,
        ...cellMetadata,
      };

      return track(eventName, props);
    },
    [productEventProperties, track],
  );
  const trackProductPicker = useCallback(
    (action: EventAction, pickerMetadata: PickerEventMetadata) => {
      const eventName: EventName = `${EventObject.PRODUCT_PICKER} ${action}`;
      const props: ProductPickerEventProperties = {
        ...productEventProperties,
        ...pickerMetadata,
      };

      return track(eventName, props);
    },
    [productEventProperties, track],
  );

  return {
    trackProduct,
    trackProductCell,
    trackProductPicker,
  };
};
