import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  ButtonGroup,
} from "@chakra-ui/react";
import { FC, useActionState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { switchRestaurant } from "@/app/actions/auth";
import { useDisclosure } from "@chakra-ui/hooks";
import isDefined from "@/utils/isDefined";
import { getAddress, SessionUser } from "@/hooks/user/utils";
import { LuMapPin } from "react-icons/lu";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import isPresent from "@/utils/isPresent";
import UpdateRestaurantModal from "@/components/Restaurants/UpdateRestaurantModal";
import { useRestaurant } from "@/hooks/restaurant/useRestaurant";

type RestaurantsProps = {
  user: SessionUser;
};

type Values = {
  restaurantID: number;
};

const Restaurants: FC<RestaurantsProps> = ({ user }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, action, pending] = useActionState(switchRestaurant, null);
  const updateAddressDisclosure = useDisclosure();
  const { updateAddress } = useRestaurant();
  const {
    register,
    reset,
    formState: { isDirty },
  } = useForm<Values>({
    defaultValues: {
      restaurantID: user.restaurantID,
    },
  });

  useEffect(() => {
    reset({
      restaurantID: user.restaurantID,
    });
  }, [reset, user.restaurantID]);

  useEffect(() => {
    // NOTE: Reset shopping session and lists if the user is switching restaurants
    if (pending) {
      shoppingSessionReactiveVar({
        data: null,
        loading: true,
      });
      shoppingListsReactiveVar(null);
    }
  }, [pending]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!user.restaurant) {
    return null;
  }

  return (
    <>
      <Button
        leftIcon={<Icon as={LuMapPin} />}
        variant="outline"
        onClick={onOpen}
        aria-label={`Restaurants`}
      >
        <Text className="max-w-full overflow-hidden text-ellipsis">
          {user.restaurant.name}
        </Text>
      </Button>
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={reset}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Switch restaurant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              as="form"
              direction="column"
              gap={4}
              action={action}
              id="restaurant"
            >
              <FormControl>
                <FormLabel>Restaurant</FormLabel>
                <Select
                  {...register("restaurantID", {
                    valueAsNumber: true,
                  })}
                >
                  {user.restaurants?.nodes
                    ?.filter(isDefined)
                    .map((restaurant) => {
                      const address = getAddress(restaurant);
                      return (
                        <option key={restaurant.id} value={restaurant.id}>
                          {[restaurant.name, address]
                            .filter(isPresent)
                            .join(", ")}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              {user.restaurant.currentAddress && (
                <>
                  <UpdateRestaurantModal
                    {...updateAddressDisclosure}
                    currentAddress={user.restaurant.currentAddress}
                    onSubmit={async (values) => {
                      await updateAddress(values);
                      updateAddressDisclosure.onClose();
                    }}
                  />
                  <Button
                    isDisabled={isDirty}
                    onClick={updateAddressDisclosure.onOpen}
                  >
                    Edit address
                  </Button>
                </>
              )}
              <Button
                isDisabled={!isDirty}
                isLoading={pending}
                colorScheme="midnight_navy"
                type="submit"
                form="restaurant"
              >
                Go shopping
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Restaurants;
