import useToastedMutation, {
  ToastPromiseOptions,
} from "@/hooks/toasted/useToastedMutation";
import { MUTATION_CREATE_SHOPPING_LIST } from "@/documents/MUTATION_CREATE_SHOPPING_LIST";
import { useCallback } from "react";
import {
  CreateShoppingListInput,
  DestroyShoppingListInput,
  UpdatePositionShoppingListInput,
  UpdateShoppingListInput,
} from "@/documents/__generated__/globalTypes.codegen";
import { QUERY_SHOPPING_LISTS } from "@/documents/QUERY_SHOPPING_LISTS";
import { MUTATION_UPDATE_SHOPPING_LIST } from "@/documents/MUTATION_UPDATE_SHOPPING_LIST";
import {
  CreateShoppingListMutation,
  CreateShoppingListMutationVariables,
} from "@/documents/__generated__/MUTATION_CREATE_SHOPPING_LIST.codegen";
import {
  UpdateShoppingListMutation,
  UpdateShoppingListMutationVariables,
} from "@/documents/__generated__/MUTATION_UPDATE_SHOPPING_LIST.codegen";
import { MUTATION_DESTROY_SHOPPING_LIST } from "@/documents/MUTATION_DESTROY_SHOPPING_LIST";
import {
  DestroyShoppingListMutation,
  DestroyShoppingListMutationVariables,
} from "@/documents/__generated__/MUTATION_DESTROY_SHOPPING_LIST.codegen";
import {
  UpdatePositionShoppingListMutation,
  UpdatePositionShoppingListMutationVariables,
} from "@/documents/__generated__/MUTATION_UPDATE_POSITION_SHOPPING_LIST.codegen";
import { MUTATION_UPDATE_POSITION_SHOPPING_LIST } from "@/documents/MUTATION_UPDATE_POSITION_SHOPPING_LIST";
import { FetchResult, MaybeMasked } from "@apollo/client";

const getToastPromiseOptions = <TData>(): ToastPromiseOptions<
  FetchResult<MaybeMasked<TData>>
> => ({
  success: () => ({
    title: `List updated`,
    status: "success",
  }),
  error: ({ message }) => ({
    title: "List update failed",
    description: message,
    status: "error",
  }),
  loading: {
    title: "Updating list",
    description: "Please wait...",
  },
  id: "update-list",
});

export const useShoppingList = () => {
  const [add, { loading: creating }] = useToastedMutation<
    CreateShoppingListMutation,
    CreateShoppingListMutationVariables
  >(MUTATION_CREATE_SHOPPING_LIST, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions: getToastPromiseOptions<CreateShoppingListMutation>(),
  });
  const [edit, { loading: updating }] = useToastedMutation<
    UpdateShoppingListMutation,
    UpdateShoppingListMutationVariables
  >(MUTATION_UPDATE_SHOPPING_LIST, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions: getToastPromiseOptions<UpdateShoppingListMutation>(),
  });
  const [drop, { loading: destroying }] = useToastedMutation<
    DestroyShoppingListMutation,
    DestroyShoppingListMutationVariables
  >(MUTATION_DESTROY_SHOPPING_LIST, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions: getToastPromiseOptions<DestroyShoppingListMutation>(),
  });
  const [position, { loading: reordering }] = useToastedMutation<
    UpdatePositionShoppingListMutation,
    UpdatePositionShoppingListMutationVariables
  >(MUTATION_UPDATE_POSITION_SHOPPING_LIST, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions:
      getToastPromiseOptions<UpdatePositionShoppingListMutation>(),
  });

  const create = useCallback(
    (input: CreateShoppingListInput) => {
      return add({
        variables: { input },
      });
    },
    [add],
  );

  const update = useCallback(
    (input: UpdateShoppingListInput) => {
      return edit({
        variables: { input },
      });
    },
    [edit],
  );

  const destroy = useCallback(
    (input: DestroyShoppingListInput) => {
      return drop({
        variables: { input },
      });
    },
    [drop],
  );

  const reorder = useCallback(
    (input: UpdatePositionShoppingListInput) => {
      return position({
        variables: { input },
      });
    },
    [position],
  );

  return {
    create,
    creating,
    update,
    updating,
    destroy,
    destroying,
    reorder,
    reordering,
  };
};
