"use client";
import { FC } from "react";
import { SessionUser } from "@/hooks/user/utils";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { BiHeart } from "react-icons/bi";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import { Link } from "@chakra-ui/next-js";
import { useReactiveVar } from "@apollo/client";

type ListNavProps = {
  user: SessionUser;
};

const ListNav: FC<ListNavProps> = () => {
  const shoppingLists = useReactiveVar(shoppingListsReactiveVar);

  if (!shoppingLists) {
    return (
      <Skeleton>
        <IconButton aria-label="lists" icon={<Icon as={BiHeart} />} />
      </Skeleton>
    );
  }

  const { productsCount, lists } = shoppingLists;

  return (
    <Menu>
      {productsCount ? (
        <MenuButton
          aria-label={`Shopping lists`}
          as={Button}
          type="button"
          colorScheme="pink"
          leftIcon={<Icon as={BiHeart} />}
        >
          {productsCount}
        </MenuButton>
      ) : (
        <MenuButton
          variant="outline"
          aria-label={`Shopping lists`}
          as={IconButton}
          icon={<Icon as={BiHeart} />}
        />
      )}
      <MenuList aria-label={`Shopping lists menu`}>
        {lists?.map((list) => (
          <MenuItem aria-label={list.name} key={list.id}>
            <Flex
              as={Link}
              prefetch={false}
              href={`/lists/${list.id}`}
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Flex gap={2} alignItems="center">
                <Box
                  style={{ backgroundColor: list.color }}
                  className="w-2 h-2 rounded-sm"
                />
                <Text textAlign="start">
                  {list.name} ({list.productsCount})
                </Text>
              </Flex>
            </Flex>
          </MenuItem>
        ))}
        <MenuItem>
          <Link w="100%" href={`/lists`} prefetch={false}>
            <Text as="b" fontSize="sm">
              View all
            </Text>
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ListNav;
