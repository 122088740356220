import {
  Alert,
  AlertIcon,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { FC, RefObject } from "react";
import { AuthError } from "@/app/lib/utils";
import { useFormContext } from "react-hook-form";
import InputPassword from "@/components/InputPassword";
import { Link } from "@chakra-ui/next-js";

export type Values = {
  phone?: string;
  password?: string;
  redirect_uri?: string;
};

type SignInFormProps = {
  action: (payload: FormData) => void;
  authError?: AuthError | null;
  initialRef?: RefObject<HTMLLabelElement | null>;
};

const SignInForm: FC<SignInFormProps> = ({ action, authError, initialRef }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <VStack
      as="form"
      align="stretch"
      role="form"
      spacing={4}
      action={action}
      id="signin"
    >
      {authError && (
        <Alert status="warning">
          <AlertIcon />
          {authError.description}
        </Alert>
      )}
      {errors.root?.serverError && (
        <Alert status="error">
          <AlertIcon />
          {errors.root?.serverError?.message}
        </Alert>
      )}
      <input type="hidden" {...register("redirect_uri")} />
      <FormControl>
        <FormLabel ref={initialRef}>Phone number</FormLabel>
        <Input
          aria-label={"Phone number"}
          {...register("phone", {
            required: true,
          })}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Password</FormLabel>
        <InputPassword
          {...register("password", {
            required: true,
          })}
        />
        <FormHelperText>
          <Link
            href="/password-recovery"
            className="!undeline"
            prefetch={false}
          >
            Forgot password?
          </Link>
        </FormHelperText>
      </FormControl>
    </VStack>
  );
};

export default SignInForm;
