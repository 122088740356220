"use client";
import { FC, useEffect, useMemo } from "react";
import { DeepPartial } from "@apollo/client/utilities";
import {
  PackageTypeEnum,
  Product,
} from "@/documents/__generated__/globalTypes.codegen";
import { Box, CardProps, VStack } from "@chakra-ui/react";
import clsx from "clsx";
import { useReactiveVar } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { FormProvider, useForm } from "react-hook-form";
import { SessionItemInput, useUpdateCart } from "@/hooks/cart/useUpdateCart";
import ProductDescription from "@/components/ProductList/ProductDetails/Description";
import {
  buildOptions,
  getPriceLevelByQuantity,
  Option,
  VariantOption,
} from "@/components/ProductList/ProductDetails/QuantitySelect";
import ImageSwitch from "@/components/ProductList/ProductDetails/ImageSwitch";
import ProductHeader from "@/components/ProductList/ProductDetails/Header";
import ProductBreadcrumbs from "@/components/ProductList/ProductDetails/Breadcrumbs";
import ProductMain from "@/components/ProductList/ProductDetails/Main";
import { useShoppingSessionProduct } from "@/hooks/session/useShoppingSessionProduct";
import { useProductEvent } from "@/hooks/analytics/trackers/useProductEvent";
import { EventAction, EventSource } from "@/app/lib/analytics/types";
import { getDollarsPrice } from "@/utils/price";
import ProductRecommendations from "@/components/ProductList/ProductDetails/Recommendations";

type ProductDetailsProps = {
  product: DeepPartial<Product>;
  variant?: CardProps["variant"];
  size?: CardProps["size"];
  includeName?: boolean;
  includeRecommendations?: boolean;
  includeDescription?: boolean;
  onUpdate?: () => void;
};

export type Inputs = {
  variants: (VariantOption & {
    options: Option[];
  })[];
};

const ProductDetails: FC<ProductDetailsProps> = ({
  variant = "unstyled",
  size = "md",
  includeName = true,
  includeRecommendations = true,
  includeDescription = true,
  onUpdate,
  ...props
}) => {
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const { product, loading } = useShoppingSessionProduct(props.product);
  const { upsert, loading: updating } = useUpdateCart();
  const { trackProduct, trackProductPicker } = useProductEvent(product);
  const defaultValues = useMemo(() => {
    return {
      variants: product.variants.map((baseVariant) => {
        if (!baseVariant?.currentVariantPricing?.priceLevels) {
          throw new Error("Price levels are required");
        }
        const [basePriceLevel] = baseVariant.currentVariantPricing.priceLevels;
        if (!basePriceLevel) {
          throw new Error("Base price level is required");
        }
        const variant = {
          basePriceLevel,
          packageType: baseVariant.packageType,
          packageTypeTitle: baseVariant.packageTypeTitle,
          quantity: baseVariant.quantity || null,
          volumeDiscountedPriceLevel: baseVariant.volumeDiscountedPriceLevel,
          orderItemId: baseVariant.orderItemId,
          itemsCount: baseVariant.itemsCount,
          ...(product.catchweightItem && {
            weight: baseVariant.weight,
            catchweightItem: product.catchweightItem,
          }),
        };
        const options = buildOptions(variant);
        return {
          ...variant,
          options,
        };
      }),
    };
  }, [product]);

  const methods = useForm<Inputs>({
    defaultValues,
  });
  const { handleSubmit, watch, reset } = methods;
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const variants = watch(["variants.0", "variants.1"]);
  const total = useMemo(() => {
    return variants.reduce((acc, variant) => {
      if (!variant || !variant.quantity) {
        return acc;
      }
      const priceLevel = getPriceLevelByQuantity(variant);
      if (!priceLevel.price) {
        return acc;
      }
      return (
        acc +
        variant.quantity * (priceLevel.discountedPrice || priceLevel.price)
      );
    }, 0);
  }, [variants]);
  return (
    <Box
      className={clsx(
        "grid gap-4",
        "grid-rows-[auto_minmax(0,1fr)_auto]",
        size === "sm" ? "grid-cols-2" : "grid-cols-[60%_40%]",
      )}
    >
      <VStack align={"stretch"} spacing={2}>
        {includeName && <ProductHeader product={product} />}
        <ProductBreadcrumbs product={product} />
      </VStack>
      <FormProvider {...methods}>
        <Box
          className="row-span-2"
          as="form"
          role={"form"}
          onSubmit={handleSubmit((values) => {
            values.variants.map(async (variant) => {
              if (sessionUser?.data?.restaurantID) {
                const input: SessionItemInput = {
                  productId: product.id,
                  packagingType: variant.packageType,
                  quantity: variant.quantity,
                  restaurantId: sessionUser?.data?.restaurantID,
                  ...(variant.orderItemId && {
                    orderItemId: variant.orderItemId,
                  }),
                };

                try {
                  await upsert(input);

                  const option = variant.options[variant.quantity || 0];
                  trackProductPicker(EventAction.CONFIRM, {
                    quantity: option.value,
                    packaging_type: variant.packageType,
                    price: variant.basePriceLevel.price,
                    subtotal: getDollarsPrice(
                      option.percentage
                        ? option.discountedPricePerQuantity
                        : option.basePricePerQuantity,
                    ),
                    source_object: EventSource.PRODUCT,
                  });

                  onUpdate?.();
                } catch (e) {
                  console.error(e);
                }
              }
            });
          })}
        >
          <ProductMain
            remind={Boolean(sessionUser?.data)}
            product={product}
            loading={updating}
            defaultDisabled={loading}
            total={total}
            variant={variant}
            onToggleReminder={(isSubscribed) =>
              trackProduct(
                isSubscribed
                  ? EventAction.TAP_REMINDER_OFF
                  : EventAction.TAP_REMINDER_ON,
              )
            }
            onBlur={({ packageType, quantity, options, basePriceLevel }) => {
              const option = options[quantity || 0];
              trackProductPicker(EventAction.BLURRED, {
                packaging_type: packageType,
                quantity,
                price: basePriceLevel.price,
                subtotal: getDollarsPrice(
                  option.percentage
                    ? option.discountedPricePerQuantity
                    : option.basePricePerQuantity,
                ),
                source_object: EventSource.PRODUCT,
              });
            }}
            onFocus={({ packageType }) => {
              trackProduct(
                packageType === PackageTypeEnum.Unit
                  ? EventAction.TAP_UNIT
                  : EventAction.TAP_CASE,
              );
            }}
            onToggleList={(inList) => {
              trackProduct(
                inList
                  ? EventAction.REMOVED_FROM_SHOPPING_LIST
                  : EventAction.ADDED_TO_SHOPPING_LIST,
              );
            }}
          />
        </Box>
      </FormProvider>
      <VStack spacing={6} align={"stretch"}>
        <ImageSwitch product={product} size={size} />
        {includeDescription && (
          <ProductDescription
            onProposition65Click={() => {
              trackProduct(EventAction.TAP_PROPOSITION_65);
            }}
            product={product}
          />
        )}
      </VStack>
      {includeRecommendations && <ProductRecommendations product={product} />}
    </Box>
  );
};

export default ProductDetails;
