import { gql } from "@apollo/client";

export const QUERY_APP_SETTINGS = gql`
  query AppSettings {
    appSettings {
      cuisineTypes {
        nodes {
          id
          name
        }
      }
    }
  }
`;
