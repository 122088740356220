import useAnalytics from "@/hooks/analytics";
import { useReactiveVar } from "@apollo/client";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { CartEventProperties, EventName } from "@/hooks/analytics/types";
import { useCallback } from "react";
import { EventAction, EventObject } from "@/app/lib/analytics/types";
import { getDollarsPrice } from "@/utils/price";

export const useCartEvent = () => {
  const { track } = useAnalytics<CartEventProperties>();
  const cart = useReactiveVar(shoppingSessionReactiveVar);
  const trackCart = useCallback(
    (action: EventAction) => {
      const eventName: EventName = `${EventObject.CART} ${action}`;

      return track(eventName, {
        shopping_session_id: cart.data?.id,
        ...(cart.data?.total && {
          subtotal: getDollarsPrice(cart.data?.total),
        }),
        quick_checkout_available: cart.data?.quickCheckoutAvailable,
      });
    },
    [cart, track],
  );
  return {
    trackCart,
  };
};
