"use client";
import { useSuspenseQuery } from "@apollo/client";
import { QUERY_APP_SETTINGS } from "@/documents/QUERY_APP_SETTINGS";
import {
  AppSettingsQuery,
  AppSettingsQueryVariables,
} from "@/documents/__generated__/QUERY_APP_SETTINGS.codegen";
import isDefined from "@/utils/isDefined";

export const useAppSettings = () => {
  const { data } = useSuspenseQuery<
    AppSettingsQuery,
    AppSettingsQueryVariables
  >(QUERY_APP_SETTINGS);

  return {
    cuisineTypes: data?.appSettings.cuisineTypes.nodes?.filter(isDefined) || [],
  };
};
