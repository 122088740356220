"use client";
import { FC } from "react";
import { isServerParseError } from "@/apollo/utils";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { InvalidTokenError } from "@/app/lib/utils";
import { FallbackProps } from "react-error-boundary";
import { isApolloError } from "@apollo/client";
import { Heading } from "@chakra-ui/react";

const fallbackRender: FC<FallbackProps> = ({ error }) => {
  if (isApolloError(error)) {
    const { graphQLErrors, networkError } = error;
    const authError = graphQLErrors.find(
      ({ extensions }) =>
        extensions?.reason ===
          '{"id":1,"code":"invalid_token","description":"invalid token"}' ||
        extensions?.code === "not_found",
    );
    if (
      authError ||
      (isServerParseError(networkError) && networkError.statusCode === 401)
    ) {
      shoppingSessionReactiveVar({
        data: null,
        loading: false,
        error,
      });
      shoppingListsReactiveVar(null);
      sessionUserReactiveVar({
        error: InvalidTokenError,
        loading: false,
      });
      return null;
    }
  }
  return (
    <Heading size="md" as="h2">
      Not found
    </Heading>
  );
};

export default fallbackRender;
