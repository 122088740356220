import { gql } from "@apollo/client";
import { FRAGMENT_SHOPPING_SESSION } from "@/documents/fragments/FRAGMENT_SHOPPING_SESSION";

export const QUERY_CART = gql`
  ${FRAGMENT_SHOPPING_SESSION}
  query Cart(
    $restaurantId: Int!
    $includeUpdatedItemsInformation: Boolean = false
  ) {
    restaurant(id: $restaurantId) {
      id
      currentShoppingSession {
        ...FragmentShoppingSession
      }
    }
  }
`;
