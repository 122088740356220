import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Tag,
  Text,
} from "@chakra-ui/react";
import ProductCard from "@/components/ProductList/ProductCard";
import { FormProvider, useForm } from "react-hook-form";
import { Delivery, OrderItem } from "@/hooks/cart/utils";
import { FC, Fragment, useEffect } from "react";
import { humanizeDate } from "@/utils/date";
import pluralize from "pluralize";
import { EventSource } from "@/app/lib/analytics/types";
import QuantitySelect from "@/components/CartDrawer/QuantitySelect";

type DeliveryListProps = {
  deliveries: Delivery[];
  eventSource: EventSource;
  loading: boolean;
  onQuantityChange: (
    quantity: number,
    orderItem: OrderItem,
  ) => Promise<unknown> | undefined;
  outOfDeliveryZone?: boolean;
};

type OrderItemsProps = {
  orderItems: OrderItem[];
  onQuantityChange: (
    quantity: number,
    orderItem: OrderItem,
  ) => Promise<unknown> | undefined;
  loading: boolean;
  eventSource: EventSource;
};

const OrderItems: FC<OrderItemsProps> = ({
  onQuantityChange,
  loading,
  orderItems,
  eventSource,
}) => {
  const methods = useForm<{
    orderItems: OrderItem[];
  }>({
    defaultValues: {
      orderItems,
    },
  });
  const { reset } = methods;
  useEffect(() => {
    reset({
      orderItems,
    });
  }, [reset]);
  return (
    <FormProvider {...methods}>
      {orderItems
        .filter((item) => item.product)
        .map((orderItem, orderItemIndex) => {
          return (
            <FormControl
              isDisabled={
                !orderItem.product?.atp || !orderItem.product?.inStock
              }
              key={orderItem.id}
              as={Grid}
              gridTemplateColumns="subgrid"
              gridColumn="span 2"
            >
              <ProductCard
                cellMetadata={{
                  position: orderItemIndex,
                  source_object: EventSource.DELIVERIES,
                }}
                subTotal={orderItem.subTotal}
                display="inline"
                size="xs"
                as={FormLabel}
                variant="unstyled"
                animate={false}
                displayOptions={{
                  add: false,
                  pricePerUOM: false,
                  subTotal: true,
                  price: false,
                  brand: true,
                  volumeDiscount: false,
                  order: true,
                  favorite: false,
                  packagingType: false,
                  jit: false,
                  specs: false,
                }}
                discountApplied={orderItem.discountApplied}
                product={orderItem.product}
              />
              <QuantitySelect
                eventSource={eventSource}
                product={orderItem.product}
                loading={loading}
                orderItem={orderItem}
                index={orderItemIndex}
                onQuantityChange={(quantity) =>
                  onQuantityChange(quantity, orderItem)
                }
              />
            </FormControl>
          );
        })}
    </FormProvider>
  );
};

const DeliveryList: FC<DeliveryListProps> = ({
  deliveries,
  eventSource,
  onQuantityChange,
  outOfDeliveryZone,
  loading,
}) => {
  return (
    <Flex direction="column" gap={4}>
      {deliveries.map((delivery) => {
        return (
          <Fragment key={delivery.id}>
            <Tag w="100%" gap={2} as={Flex} justifyContent="space-between">
              {delivery.closestAvailableDate && (
                <Text
                  fontSize="sm"
                  as="b"
                  {...(!delivery.isAvailable && {
                    color: "red.500",
                  })}
                >
                  {outOfDeliveryZone
                    ? "Not available in your area"
                    : delivery.isAvailable
                      ? `Available ${humanizeDate(delivery.closestAvailableDate)}`
                      : `No available deliveries`}
                </Text>
              )}
              {delivery.productsCount && (
                <Text fontSize="sm" as="b">
                  {delivery.productsCount}{" "}
                  {pluralize("item", delivery.productsCount)}
                </Text>
              )}
            </Tag>
            {delivery.orders.map((order) => {
              return (
                <Flex key={order.id} direction="column" gap={4}>
                  <Grid
                    gridTemplateColumns="1fr auto"
                    gridTemplateRows="auto"
                    gap={2}
                  >
                    <OrderItems
                      eventSource={eventSource}
                      loading={loading}
                      onQuantityChange={onQuantityChange}
                      orderItems={order.orderItems}
                    />
                  </Grid>
                </Flex>
              );
            })}
          </Fragment>
        );
      })}
    </Flex>
  );
};

export default DeliveryList;
