import useToastedMutation from "@/hooks/toasted/useToastedMutation";
import { MUTATION_CHECKOUT_SHOPPING_SESSION } from "@/documents/MUTATION_CHECKOUT_SHOPPING_SESSION";
import {
  CheckoutShoppingSessionMutation,
  CheckoutShoppingSessionMutationVariables,
} from "@/documents/__generated__/MUTATION_CHECKOUT_SHOPPING_SESSION.codegen";
import { useCallback } from "react";
import {
  CheckoutShoppingSessionInput,
  UpdateShoppingSessionInput,
} from "@/documents/__generated__/globalTypes.codegen";
import { findAtpRejectionError } from "@/hooks/cart/utils";
import { useLazyQuery } from "@apollo/client";
import {
  CartQuery,
  CartQueryVariables,
} from "@/documents/__generated__/QUERY_CART.codegen";
import { useShoppingSession } from "@/hooks/session/useShoppingSession";
import { QUERY_CART } from "@/documents/QUERY_CART";
import { parseShipments } from "@/hooks/shipments/utils";
import { MUTATION_UPDATE_SHOPPING_SESSION } from "@/documents/MUTATION_UPDATE_SHOPPING_SESSION";
import {
  UpdateShoppingSessionMutation,
  UpdateShoppingSessionMutationVariables,
} from "@/documents/__generated__/MUTATION_UPDATE_SHOPPING_SESSION.codegen";

export const useCheckout = () => {
  const [mutate, { loading, data: success, error }] = useToastedMutation<
    CheckoutShoppingSessionMutation,
    CheckoutShoppingSessionMutationVariables
  >(MUTATION_CHECKOUT_SHOPPING_SESSION);
  const [query, { data }] = useLazyQuery<CartQuery, CartQueryVariables>(
    QUERY_CART,
    {
      fetchPolicy: "network-only",
    },
  );
  const [update, { loading: rescheduling }] = useToastedMutation<
    UpdateShoppingSessionMutation,
    UpdateShoppingSessionMutationVariables
  >(MUTATION_UPDATE_SHOPPING_SESSION);

  const reschedule = useCallback(
    (input: UpdateShoppingSessionInput) => {
      return update({
        variables: {
          input,
          restaurantId: input.restaurantId,
        },
      });
    },
    [update],
  );

  const checkout = useCallback(
    (input: CheckoutShoppingSessionInput) => {
      return mutate({
        variables: {
          input,
          restaurantId: input.restaurantId,
        },
        errorPolicy: "all",
        onError: (error) => {
          const atpError = findAtpRejectionError(error);

          if (atpError) {
            query({
              variables: {
                restaurantId: input.restaurantId,
                includeUpdatedItemsInformation: true,
              },
            });
          }
        },
        onCompleted: () => {
          query({
            variables: {
              restaurantId: input.restaurantId,
            },
          });
        },
        context: {
          skipErrorLogging: true,
        },
      }).catch(console.warn);
    },
    [mutate, query],
  );

  useShoppingSession(
    success?.checkoutShoppingSession?.shoppingSession ||
      data?.restaurant.currentShoppingSession,
  );

  return {
    checkout,
    loading,
    error,
    shipments: parseShipments(
      success?.checkoutShoppingSession?.shipments || [],
    ),
    reschedule,
    rescheduling,
  };
};
