import { Maybe } from "@/documents/__generated__/globalTypes.codegen";

export const getDollarsPrice = (price: number = 0): number => {
  return price / 100;
};

const DEFAULT_CURRENCY_OPTIONS: Intl.NumberFormatOptions = {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  compactDisplay: "short",
};

export const getDollarsDisplayPrice = (
  price?: Maybe<number>,
  {
    showZeroPlaceholder,
    ...rest
  }: Intl.NumberFormatOptions & {
    showZeroPlaceholder?: boolean;
  } = {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    compactDisplay: "short",

    showZeroPlaceholder: true,
  },
): string => {
  price = price || 0;
  const options = { ...DEFAULT_CURRENCY_OPTIONS, ...rest };
  if (price === 0 && showZeroPlaceholder) {
    return "Free";
  }
  return new Intl.NumberFormat("en-US", options).format(getDollarsPrice(price));
};
