import useToastedMutation from "@/hooks/toasted/useToastedMutation";
import { MUTATION_UPDATE_RESTAURANT } from "@/documents/MUTATION_UPDATE_RESTAURANT";
import {
  UpdateRestaurantMutation,
  UpdateRestaurantMutationVariables,
} from "@/documents/__generated__/MUTATION_UPDATE_RESTAURANT.codegen";
import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { Address } from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";
import { getAddress } from "@/hooks/user/utils";

export const useRestaurant = () => {
  const [mutate, { loading }] = useToastedMutation<
    UpdateRestaurantMutation,
    UpdateRestaurantMutationVariables
  >(MUTATION_UPDATE_RESTAURANT);
  const sessionUser = useReactiveVar(sessionUserReactiveVar);

  const updateNote = useCallback(
    (note: string) => {
      if (!sessionUser?.data) {
        throw new Error("User not logged in");
      }
      return mutate({
        variables: {
          input: {
            restaurantId: sessionUser?.data.restaurantID,
            restaurantNote: note,
          },
        },
      });
    },
    [mutate, sessionUser?.data],
  );

  const updateAddress = useCallback(
    (address: DeepPartial<Address>) => {
      if (!sessionUser?.data) {
        throw new Error("User not logged in");
      }
      return mutate({
        variables: {
          input: {
            address: address.addressLine,
            city: address.city,
            state: address.state,
            zipcode: address.zipcode,
            restaurantId: sessionUser.data.restaurantID,
          },
        },
        onCompleted: (result) => {
          if (!sessionUser?.data) {
            throw new Error("User not logged in");
          }
          const restaurant = result.updateRestaurant?.restaurant;
          if (restaurant) {
            sessionUserReactiveVar({
              ...sessionUser,
              data: {
                ...sessionUser.data,
                restaurants: {
                  ...sessionUser.data.restaurants,
                  nodes: sessionUser.data.restaurants?.nodes?.map((r) => {
                    if (r?.id === restaurant.id) {
                      return restaurant;
                    }
                    return r;
                  }),
                },
                restaurant: {
                  ...restaurant,
                  address: getAddress(restaurant),
                },
              },
            });
          }
        },
      });
    },
    [mutate, sessionUser],
  );

  return {
    updateNote,
    updateAddress,
    loading,
  };
};
