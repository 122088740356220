"use client";
import { Box, Flex, Text } from "@chakra-ui/react";
import SignInGate from "@/components/SignInGate";
import { FC } from "react";
import { Product } from "@/hooks/products/utils";
import ListMenu from "@/components/Lists/ListMenu";

type ProductListsProps = {
  product: Product;
  onToggleList: (inList: boolean) => void;
};

const ProductLists: FC<ProductListsProps> = ({ product, onToggleList }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box>
        <Text as="b" fontSize="sm">
          Buy this item often?
        </Text>
        <Text fontSize="xs">Add it to a list for faster shopping</Text>
      </Box>
      <SignInGate>
        <ListMenu onToggle={onToggleList} id={product.id} sku={product.sku} />
      </SignInGate>
    </Flex>
  );
};

export default ProductLists;
