"use client";
import { FC, useCallback, useMemo, MouseEvent } from "react";
import CartDrawer from "@/components/CartDrawer";
import { useReactiveVar } from "@apollo/client";
import { SessionUser } from "@/hooks/user/utils";
import { parseCart, parseDeliveries } from "@/hooks/cart/utils";
import { useDisclosure } from "@chakra-ui/hooks";
import { useBoolean } from "@chakra-ui/icons";
import { useMoveItemsDeliveries } from "@/hooks/cart/useMoveItemsDeliveries";
import { useImportFromPreviousShoppingSession } from "@/hooks/cart/useImportFromPreviousShoppingSession";
import { useCheckout } from "@/hooks/cart/useCheckout";
import { isUpdatingSessionReactiveVar } from "@/hooks/cart/useUpdateCart";
import { useCartEvent } from "@/hooks/analytics/trackers/useCartEvent";
import { useRouter } from "next/navigation";
import isDefined from "@/utils/isDefined";
import { epochToISODateString } from "@/utils/date";
import { EventAction, EventSource } from "@/app/lib/analytics/types";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { isApolloError } from "@apollo/client/errors";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { InvalidTokenError } from "@/app/lib/utils";
import { Button, Skeleton } from "@chakra-ui/react";
import DeliveriesContainer from "@/containers/deliveries";
import ClearCartModal from "@/components/ClearCartModal";
import { useClearCart } from "@/hooks/cart/useClearCart";

type CartDrawerContainerProps = {
  cart: ReturnType<typeof parseCart>;
  user: SessionUser;
};

const CartDrawerContainer: FC<CartDrawerContainerProps> = ({ cart, user }) => {
  const drawerDisclosure = useDisclosure();
  const isEmpty = useMemo(
    () => cart.deliveries.length === 0,
    [cart.deliveries],
  );
  const { onOpen, onClose } = drawerDisclosure;
  const popoverDisclosure = useDisclosure();
  const clearCartDisclosure = useDisclosure();
  const [allowed, { on, off }] = useBoolean(false);
  const { moveItemsDeliveries, loading, deliveries } = useMoveItemsDeliveries();
  const { importFromPreviousShoppingSession, loading: importing } =
    useImportFromPreviousShoppingSession();
  const { checkout, loading: checkingOut } = useCheckout();
  const { clearCart } = useClearCart();
  const isUpdating = useReactiveVar(isUpdatingSessionReactiveVar);
  const { trackCart } = useCartEvent();
  const { push } = useRouter();
  const handleImport = useCallback(() => {
    return importFromPreviousShoppingSession({
      restaurantId: user.restaurantID,
    });
  }, [importFromPreviousShoppingSession, user.restaurantID]);
  const input = useMemo(() => {
    if (deliveries) {
      const deliveryInputs = parseDeliveries(deliveries).map(
        ({ closestAvailableTime, orders }) => {
          const orderIds = orders?.map((order) => order?.id).filter(isDefined);
          if (!orderIds) {
            throw new Error("orderIds is required");
          }
          if (!closestAvailableTime) {
            throw new Error("closestAvailableTime is required");
          }
          const { date, start, end, id } = closestAvailableTime;

          if (!date || start == null || end == null || id == null) {
            throw new Error("closestAvailableTime is missing required fields");
          }

          return {
            orderIds,
            deliveryDate: epochToISODateString(date),
            deliveryTimeStart: start,
            deliveryTimeEnd: end,
            deliveryWindowId: id,
          };
        },
      );
      return {
        restaurantId: user.restaurantID,
        deliveries: deliveryInputs,
      };
    }
  }, [deliveries, user.restaurantID]);

  const handleMergeOrders = useCallback(async () => {
    on();
    if (input) {
      await checkout(input);
      off();
      popoverDisclosure.onClose();
      onClose();
    }
  }, [checkout, input, off, on, onClose, popoverDisclosure]);

  const handeClearCart = useCallback(async () => {
    await clearCart({
      restaurantId: user.restaurantID,
    });

    clearCartDisclosure.onClose();
  }, [clearCartDisclosure, clearCart, user.restaurantID]);

  const handleOpen = useCallback(() => {
    onOpen();
    trackCart(EventAction.VIEW);
  }, [onOpen, trackCart]);
  const handleClose = useCallback(() => {
    onClose();
    off();
    popoverDisclosure.onClose();
  }, [off, onClose, popoverDisclosure]);
  const handleAddPreviousCartItems = useCallback(() => {
    handleImport();
    trackCart(EventAction.TAP_ADD_PREVIOUS_CART_ITEMS);
  }, [handleImport, trackCart]);
  const handleNavigateToCheckout = useCallback(() => {
    trackCart(EventAction.TAP_CHECKOUT);
  }, [trackCart]);

  const handleCheckout = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      trackCart(EventAction.TAP_CHECKOUT);
      if (!allowed) {
        e.preventDefault();
        const { data } = await moveItemsDeliveries(user.restaurantID);
        if (
          data?.restaurant?.currentShoppingSession?.moveItemsDeliveries?.length
        ) {
          popoverDisclosure.onOpen();
        } else {
          const target = e.target as unknown as HTMLAnchorElement;
          off();
          push(target.href);
        }
      }
    },
    [
      allowed,
      moveItemsDeliveries,
      off,
      popoverDisclosure,
      push,
      trackCart,
      user.restaurantID,
    ],
  );

  return (
    <CartDrawer
      {...drawerDisclosure}
      cartTotal={cart.cartTotal}
      loading={loading}
      importing={importing}
      productCount={cart.productCount}
      handleAddPreviousCartItems={handleAddPreviousCartItems}
      handleCheckout={handleCheckout}
      deliveriesCount={cart.deliveries.length}
      handleOpen={handleOpen}
      handleClose={handleClose}
      isEmpty={isEmpty}
      handleNavigateToCheckout={handleNavigateToCheckout}
      popoverDisclosure={popoverDisclosure}
      moveItemsDeliveries={deliveries}
      isUpdating={isUpdating}
      handleMergeOrders={handleMergeOrders}
      checkingOut={checkingOut}
      handleOpenClearCart={clearCartDisclosure.onOpen}
    >
      <ClearCartModal {...clearCartDisclosure} onSubmit={handeClearCart} />
      <DeliveriesContainer
        eventSource={EventSource.CART}
        user={user}
        deliveries={cart.deliveries}
      />
    </CartDrawer>
  );
};

type CartContainerProps = {
  user: SessionUser;
};

const fallbackRender: FC<FallbackProps> = ({ error }) => {
  if (isApolloError(error)) {
    const { graphQLErrors } = error;
    const authError = graphQLErrors.find(
      ({ extensions }) =>
        extensions?.reason ===
          '{"id":1,"code":"invalid_token","description":"invalid token"}' ||
        extensions?.code === "not_found",
    );
    if (authError) {
      shoppingSessionReactiveVar({
        data: null,
        loading: false,
      });
      shoppingListsReactiveVar(null);
      sessionUserReactiveVar({
        error: InvalidTokenError,
        loading: false,
      });
      return null;
    }
  }
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
};

const CartContainer: FC<CartContainerProps> = ({ user }) => {
  const cart = useReactiveVar(shoppingSessionReactiveVar);

  if (cart.loading || !cart.data) {
    return (
      <Skeleton>
        <Button>Cart</Button>
      </Skeleton>
    );
  }

  if (cart.error) {
    return null;
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <CartDrawerContainer cart={cart.data} user={user} />
    </ErrorBoundary>
  );
};

export default CartContainer;
