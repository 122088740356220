import {
  UpdateShoppingSessionItemMutation,
  UpdateShoppingSessionItemMutationVariables,
} from "@/documents/__generated__/MUTATION_UPDATE_SHOPPING_SESSION_ITEM.codegen";
import { MUTATION_UPDATE_SHOPPING_SESSION_ITEM } from "@/documents/MUTATION_UPDATE_SHOPPING_SESSION_ITEM";
import { useCallback, useEffect } from "react";
import {
  CreateShoppingSessionItemInput,
  DestroyShoppingSessionItemInput,
  UpdateShoppingSessionItemInput,
} from "@/documents/__generated__/globalTypes.codegen";
import { MUTATION_CREATE_SHOPPING_SESSION_ITEM } from "@/documents/MUTATION_CREATE_SHOPPING_SESSION_ITEM";
import {
  CreateShoppingSessionItemMutation,
  CreateShoppingSessionItemMutationVariables,
} from "@/documents/__generated__/MUTATION_CREATE_SHOPPING_SESSION_ITEM.codegen";
import { MUTATION_DESTROY_SHOPPING_SESSION_ITEM } from "@/documents/MUTATION_DESTROY_SHOPPING_SESSION_ITEM";
import {
  DestroyShoppingSessionItemMutation,
  DestroyShoppingSessionItemMutationVariables,
} from "@/documents/__generated__/MUTATION_DESTROY_SHOPPING_SESSION_ITEM.codegen";
import useToastedMutation, {
  ToastPromiseOptions,
} from "@/hooks/toasted/useToastedMutation";
import { FetchResult, MaybeMasked, useReactiveVar } from "@apollo/client";
import { makeVar as makeLove } from "@apollo/client";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";

export const isUpdatingSessionReactiveVar = makeLove<boolean>(false);

type Input<T> = Omit<T, "quantity"> & {
  quantity?: number | null;
};

type UpdateItemInput = Input<UpdateShoppingSessionItemInput>;
type DestroyItemInput = Input<DestroyShoppingSessionItemInput>;
type CreateItemInput = Input<CreateShoppingSessionItemInput>;

export type SessionItemInput =
  | UpdateItemInput
  | DestroyItemInput
  | CreateItemInput;

const isUpdateShoppingSessionItemInput = (
  input: SessionItemInput,
): input is UpdateItemInput => {
  return (
    Object.hasOwn(input, "orderItemId") &&
    input.quantity != null &&
    input.quantity > 0
  );
};

const isDestroyShoppingSessionItemInput = (
  input: SessionItemInput,
): input is DestroyItemInput => {
  return Object.hasOwn(input, "orderItemId") && input.quantity === 0;
};

const getToastPromiseOptions = <TData>(): ToastPromiseOptions<
  FetchResult<MaybeMasked<TData>>
> => ({
  success: () => ({
    title: `Cart updated`,
    description: `Item updated`,
    status: "success",
  }),
  error: ({ message }) => ({
    title: "Cart update failed",
    description: message,
    status: "error",
  }),
  loading: {
    title: "Updating cart",
    description: "Please wait...",
  },
  id: "update-cart",
});

export const useUpdateCart = () => {
  const cart = useReactiveVar(shoppingSessionReactiveVar);
  const [update, { loading: updating, error }] = useToastedMutation<
    UpdateShoppingSessionItemMutation,
    UpdateShoppingSessionItemMutationVariables
  >(MUTATION_UPDATE_SHOPPING_SESSION_ITEM, {
    toastPromiseOptions:
      getToastPromiseOptions<UpdateShoppingSessionItemMutation>(),
  });
  const [create, { loading: creating }] = useToastedMutation<
    CreateShoppingSessionItemMutation,
    CreateShoppingSessionItemMutationVariables
  >(MUTATION_CREATE_SHOPPING_SESSION_ITEM, {
    toastPromiseOptions:
      getToastPromiseOptions<CreateShoppingSessionItemMutation>(),
  });
  const [destroy, { loading: destroying }] = useToastedMutation<
    DestroyShoppingSessionItemMutation,
    DestroyShoppingSessionItemMutationVariables
  >(MUTATION_DESTROY_SHOPPING_SESSION_ITEM, {
    toastPromiseOptions:
      getToastPromiseOptions<DestroyShoppingSessionItemMutation>(),
  });

  useEffect(() => {
    if (creating || updating || destroying) {
      isUpdatingSessionReactiveVar(true);
    } else {
      isUpdatingSessionReactiveVar(false);
    }
  }, [creating, destroying, updating]);

  const upsert = useCallback(
    (input: SessionItemInput) => {
      isUpdatingSessionReactiveVar(true);
      if (isDestroyShoppingSessionItemInput(input)) {
        return destroy({
          variables: {
            input: {
              restaurantId: input.restaurantId,
              orderItemId: input.orderItemId,
            },
            restaurantId: input.restaurantId,
          },
        });
      } else if (isUpdateShoppingSessionItemInput(input) && input.quantity) {
        const shouldUpdate =
          cart?.data?.getOrderItem(input.orderItemId)?.quantity !==
          input.quantity;
        if (shouldUpdate) {
          return update({
            variables: {
              input: {
                orderItemId: input.orderItemId,
                quantity: input.quantity,
                packagingType: input.packagingType,
                restaurantId: input.restaurantId,
              },
              restaurantId: input.restaurantId,
            },
          });
        }
      } else if (input.quantity) {
        return create({
          variables: {
            input: {
              productId: input.productId,
              quantity: input.quantity,
              packagingType: input.packagingType,
              restaurantId: input.restaurantId,
            },
            restaurantId: input.restaurantId,
          },
        });
      }
    },
    [cart, create, destroy, update],
  );

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return {
    upsert,
    loading: creating || updating || destroying,
  };
};
