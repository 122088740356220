import { FC } from "react";
import { Grid, GridItem, Icon } from "@chakra-ui/react";
import { BiSolidBookmarkHeart } from "react-icons/bi";
import ProductCart from "@/components/ProductList/ProductCard/Cart";
import clsx from "clsx";
import ProductImage from "@/components/ProductList/ProductCard/Image";
import ProductName from "@/components/ProductList/ProductCard/Name";
import ProductMeta from "@/components/ProductList/ProductCard/Meta";
import { BodyProps } from "@/components/ProductList/ProductCard/Body";

const ListView: FC<BodyProps> = ({
  options,
  baseProduct,
  product,
  onClick,
  children,
  as,
  animate,
  discountApplied,
  subTotal,
}) => {
  const { isFavourite, orderItems } = baseProduct;
  return (
    <Grid gap={4} alignItems="center" className="relative inherit">
      {options.favorite && isFavourite && (
        <Icon
          as={BiSolidBookmarkHeart}
          color="pink.500"
          boxSize={8}
          className="absolute z-10"
        />
      )}
      {options.add ? (
        <GridItem area="cta">
          <ProductCart
            id={baseProduct.id}
            product={product}
            orderItems={orderItems}
            onClick={onClick}
          >
            {children}
          </ProductCart>
        </GridItem>
      ) : (
        <GridItem
          area="cta"
          className={clsx({
            ["inherit"]: as === GridItem,
          })}
        >
          {children}
        </GridItem>
      )}
      <GridItem
        area="img"
        className={clsx("relative h-full", {
          ["group-hover:scale-110 transition-transform origin-center"]: animate,
        })}
      >
        <ProductImage {...baseProduct} />
      </GridItem>
      <GridItem area="name">
        <ProductName {...baseProduct} options={options} />
      </GridItem>
      {options?.meta && (
        <ProductMeta
          {...baseProduct}
          options={options}
          discountApplied={discountApplied}
          subTotal={subTotal}
        />
      )}
    </Grid>
  );
};

export default ListView;
