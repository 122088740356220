import { gql } from "@apollo/client";
import { FRAGMENT_SHOPPING_SESSION } from "@/documents/fragments/FRAGMENT_SHOPPING_SESSION";

export const MUTATION_DESTROY_SHOPPING_SESSION_ITEM = gql`
  ${FRAGMENT_SHOPPING_SESSION}
  mutation DestroyShoppingSessionItem(
    $input: DestroyShoppingSessionItemInput!
    $restaurantId: Int!
    $includeUpdatedItemsInformation: Boolean = false
  ) {
    destroyShoppingSessionItem(input: $input) {
      shoppingSession {
        ...FragmentShoppingSession
      }
    }
  }
`;
