import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";

export const QUERY_RECOMMENDATIONS = gql`
  ${FRAGMENT_PRODUCT}
  query Recommendations(
    $restaurantId: Int
    $source: RecommendationSourceEnum!
    $sku: String
  ) {
    restaurant(id: $restaurantId) {
      id
      recommendations(source: $source, sku: $sku) {
        ... on RecommendedProduct {
          product {
            ...FragmentProduct
          }
        }
        ... on GoogleAiRecommendedProduct {
          product {
            ...FragmentProduct
          }
        }
      }
    }
  }
`;
