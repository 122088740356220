import {
  startTransition,
  useActionState,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Reminder,
  subscribe,
  subscriptions,
  unsubscribe,
} from "@/app/actions/reminder";
import { useBoolean } from "@chakra-ui/react";

export const useReminder = (id: number) => {
  const [isSubscribed, { on, off }] = useBoolean();
  const [isLoading, { toggle }] = useBoolean(true);
  const [reminders, getReminders] = useActionState<Reminder[] | null, number>(
    subscriptions,
    null,
  );
  const [reminder, setReminder] = useState<Reminder>();

  useEffect(() => {
    startTransition(() => {
      return getReminders(id);
    });
  }, [getReminders, id]);

  useEffect(() => {
    if (reminders) {
      toggle();
      const [reminder] = reminders;

      if (reminder) {
        setReminder(reminder);
        on();
      } else {
        off();
      }
    }
  }, [off, on, reminders, toggle]);

  const toggleSubscription = useCallback(() => {
    toggle();
    if (isSubscribed && reminder) {
      unsubscribe(reminder.id).then(() => {
        off();
        setReminder(undefined);
        toggle();
      });
    } else {
      subscribe(id).then((reminder) => {
        on();
        setReminder(reminder);
        toggle();
      });
    }
  }, [id, isSubscribed, off, on, reminder, toggle]);
  return {
    isSubscribed,
    isLoading,
    toggleSubscription,
  };
};
