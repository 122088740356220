"use client";
import { FC, RefObject, MouseEvent, useRef, PropsWithChildren } from "react";
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BiCartAlt, BiTrash } from "react-icons/bi";
import { Link } from "@chakra-ui/next-js";
import { getDollarsDisplayPrice } from "@/utils/price";
import isDefined from "@/utils/isDefined";
import { humanizeDate } from "@/utils/date";
import pluralize from "pluralize";
import Image from "next/image";
import { UseDisclosureReturn } from "@chakra-ui/hooks/use-disclosure";
import { ShoppingSessionDelivery } from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";

type CartDrawerProps = UseDisclosureReturn & {
  handleOpen: () => void;
  handleClose: () => void;
  handleAddPreviousCartItems: () => void;
  handleNavigateToCheckout: (e: MouseEvent<HTMLButtonElement>) => void;
  handleCheckout: (e: MouseEvent<HTMLButtonElement>) => void;
  productCount?: number;
  cartTotal?: number | null;
  isEmpty: boolean;
  deliveriesCount?: number;
  moveItemsDeliveries?: DeepPartial<ShoppingSessionDelivery>[];
  importing: boolean;
  isUpdating: boolean;
  loading: boolean;
  checkingOut: boolean;
  popoverDisclosure: UseDisclosureReturn;
  handleMergeOrders: () => void;
  handleOpenClearCart: () => void;
};

const CartDrawer: FC<PropsWithChildren<CartDrawerProps>> = ({
  isOpen,
  handleOpen,
  handleClose,
  productCount,
  isEmpty,
  importing,
  handleAddPreviousCartItems,
  popoverDisclosure,
  handleNavigateToCheckout,
  isUpdating,
  loading,
  handleCheckout,
  cartTotal,
  moveItemsDeliveries,
  handleMergeOrders,
  checkingOut,
  deliveriesCount,
  handleOpenClearCart,
  children,
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button
        aria-label={`Cart`}
        leftIcon={<Icon as={BiCartAlt} boxSize={5} />}
        ref={btnRef}
        colorScheme="blue"
        onClick={handleOpen}
      >
        {productCount}
      </Button>
      <Drawer
        size="sm"
        isOpen={isOpen}
        placement="right"
        onClose={handleClose}
        finalFocusRef={btnRef as RefObject<HTMLButtonElement>}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack>
              <Heading size="md">Cart</Heading>
              <IconButton
                isDisabled={isEmpty}
                onClick={handleOpenClearCart}
                aria-label="Clear cart"
                variant="ghost"
                icon={<Icon as={BiTrash} />}
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody className="relative">
            {!isEmpty ? (
              children
            ) : (
              <Flex alignItems="center" justifyContent="space-around" h="100%">
                <VStack spacing={6}>
                  <Image
                    src="/img/empty_cart.png"
                    alt="Your cart is empty"
                    width={150}
                    height={150}
                  />
                  <Heading as="h4" size="md">
                    Your cart is empty
                  </Heading>
                  <Text color="gray.600">
                    Items you add to your cart will appear here.
                  </Text>
                </VStack>
              </Flex>
            )}
          </DrawerBody>

          <DrawerFooter>
            {isEmpty ? (
              <Button
                w="100%"
                isLoading={importing}
                colorScheme="midnight_navy"
                onClick={handleAddPreviousCartItems}
              >
                Add previous cart items
              </Button>
            ) : (
              <Popover {...popoverDisclosure} matchWidth closeOnBlur={false}>
                <PopoverTrigger>
                  {popoverDisclosure.isOpen ? (
                    <Button
                      w="100%"
                      as={Link}
                      href="/checkout"
                      isDisabled={isUpdating || loading}
                      onClick={handleNavigateToCheckout}
                    >
                      No, continue to checkout
                    </Button>
                  ) : (
                    <Button
                      w="100%"
                      aria-label={`Go to checkout`}
                      isLoading={loading}
                      onClick={handleCheckout}
                      as={Link}
                      href="/checkout"
                      isDisabled={isUpdating}
                      colorScheme="midnight_navy"
                    >
                      Go to checkout
                      {cartTotal ? ` ${getDollarsDisplayPrice(cartTotal)}` : ""}
                    </Button>
                  )}
                </PopoverTrigger>
                <PopoverContent w="100%">
                  <PopoverHeader>
                    <Heading size="sm" as="h5">
                      Do you want to add your items to the following{" "}
                      {pluralize("order", deliveriesCount)}?
                    </Heading>
                  </PopoverHeader>
                  <PopoverBody>
                    <VStack
                      align="stretch"
                      divider={<StackDivider borderColor="gray.200" />}
                      spacing={2}
                    >
                      {moveItemsDeliveries
                        ?.filter(isDefined)
                        .map(
                          ({
                            availableDeliveryTimes,
                            id,
                            productsCount,
                            cartTotal,
                          }) => {
                            const [time] =
                              availableDeliveryTimes?.nodes?.filter(
                                isDefined,
                              ) || [];
                            if (time.date == null) {
                              console.error("time.date is required");

                              return null;
                            }
                            return (
                              <Flex gap={2} key={id} alignItems="center">
                                <Text
                                  fontSize="sm"
                                  as="b"
                                  className="capitalize"
                                >
                                  {humanizeDate(time.date)},{" "}
                                  {time.customerFacingMessage}
                                </Text>
                                <Box>
                                  <Badge size="sm" colorScheme="blue">
                                    +{productsCount}{" "}
                                    {pluralize("product", productsCount)}{" "}
                                    {getDollarsDisplayPrice(cartTotal)}
                                  </Badge>
                                </Box>
                              </Flex>
                            );
                          },
                        )}
                    </VStack>
                  </PopoverBody>
                  <PopoverFooter>
                    <Button
                      w="100%"
                      onClick={handleMergeOrders}
                      isLoading={checkingOut}
                      colorScheme="midnight_navy"
                    >
                      Yes, add to existing {pluralize("order", deliveriesCount)}
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CartDrawer;
