import { gql } from "@apollo/client";
import { FRAGMENT_SHOPPING_SESSION } from "@/documents/fragments/FRAGMENT_SHOPPING_SESSION";

export const MUTATION_UPDATE_SHOPPING_SESSION_ITEM = gql`
  ${FRAGMENT_SHOPPING_SESSION}
  mutation UpdateShoppingSessionItem(
    $input: UpdateShoppingSessionItemInput!
    $restaurantId: Int!
    $includeUpdatedItemsInformation: Boolean = false
  ) {
    updateShoppingSessionItem(input: $input) {
      shoppingSession {
        ...FragmentShoppingSession
      }
    }
  }
`;
