"use client";
import {
  FC,
  useActionState,
  useEffect,
  useState,
  ReactNode,
  useRef,
  RefObject,
  useMemo,
} from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import { deleteCookie } from "cookies-next/client";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Text,
  VStack,
  Divider,
  ButtonGroup,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { auth } from "@/app/actions/auth";
import { makeVar as makeLove } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { usePathname } from "next/navigation";
import { Maybe } from "@/documents/__generated__/globalTypes.codegen";
import { RESTAURANT_ID_COOKIE_NAME, TOKEN_COOKIE_NAME } from "@/apollo/utils";
import { AuthError } from "@/app/lib/session";
import { UseDisclosureReturn } from "@chakra-ui/hooks/use-disclosure";
import { isFunction } from "lodash";
import SignInForm, { Values } from "@/components/SignInForm";
import { Link } from "@chakra-ui/next-js";
import Incomplete from "@/components/SignUpForm/Incomplete";

type SignInModalProps = {
  error?: Maybe<AuthError>;
  children?: ReactNode | ((props: UseDisclosureReturn) => ReactNode);
};

export const signInDisclosureReactiveVar = makeLove<UseDisclosureReturn | null>(
  null,
);

const SignInModal: FC<SignInModalProps> = ({ error, children }) => {
  const pathname = usePathname();
  const [authError, setAuthError] = useState(error);
  const [state, action, pending] = useActionState(auth, null);
  const methods = useForm<Values>({
    defaultValues: {
      redirect_uri: pathname,
    },
  });

  const {
    setValue,
    setError,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setValue("redirect_uri", pathname);
  }, [pathname, setValue]);

  useEffect(() => {
    if (error?.code === "invalid_token") {
      setAuthError(error);
      deleteCookie(TOKEN_COOKIE_NAME);
    }
  }, [error]);

  useEffect(() => {
    if (state?.error) {
      setError("root.serverError", {
        type: state.error.code,
        message: state.error.description,
      });

      sessionUserReactiveVar({
        loading: false,
        data: undefined,
      });
    }
  }, [setError, state?.error]);

  useEffect(() => {
    if (pending) {
      sessionUserReactiveVar({
        loading: true,
        data: undefined,
      });
    }
  }, [pending, state]);

  const modalDisclosure = useDisclosure({
    ...(Boolean(authError) && {
      isOpen: true,
    }),
  });

  useEffect(() => {
    signInDisclosureReactiveVar(modalDisclosure);

    return () => {
      signInDisclosureReactiveVar(null);
    };
  }, [modalDisclosure]);
  const isIncomplete = useMemo(
    () => errors.root?.serverError.type === "missing_restaurant",
    [errors.root?.serverError.type],
  );

  const { isOpen, onClose } = modalDisclosure;
  const initialRef = useRef<HTMLLabelElement>(null);

  return (
    <FormProvider {...methods}>
      {isFunction(children) ? children(modalDisclosure) : children}
      <Modal
        initialFocusRef={initialRef as RefObject<HTMLLabelElement>}
        isOpen={isOpen}
        onClose={() => {
          setAuthError(null);
          deleteCookie(RESTAURANT_ID_COOKIE_NAME);
          onClose();
        }}
        onCloseComplete={reset}
      >
        <ModalOverlay />
        <ModalContent role="dialog" aria-label="Sign in dialog">
          <ModalHeader>Sign in</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isIncomplete ? (
              <Incomplete />
            ) : (
              <SignInForm
                action={action}
                initialRef={initialRef}
                authError={authError}
              />
            )}
          </ModalBody>

          <ModalFooter>
            {isIncomplete ? (
              <ButtonGroup>
                <Button type="button" onClick={onClose}>
                  Continue as a guest
                </Button>
                <Button
                  as={Link}
                  href="/signup"
                  aria-label={"Complete signup"}
                  colorScheme="midnight_navy"
                >
                  Complete sign up
                </Button>
              </ButtonGroup>
            ) : (
              <VStack align="stretch" w="100%" spacing={4}>
                <Button
                  isLoading={pending}
                  colorScheme="midnight_navy"
                  type="submit"
                  form="signin"
                >
                  Sign in
                </Button>
                <Divider />
                <HStack justify="space-between">
                  <Text fontSize="sm">Don&#39;t have an account?</Text>
                  <Button as={Link} href="/signup" prefetch={false}>
                    Sign up
                  </Button>
                </HStack>
              </VStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};

export default SignInModal;
