import { gql } from "@apollo/client";

export const MUTATION_UPDATE_POSITION_SHOPPING_LIST_ITEM = gql`
  mutation UpdatePositionShoppingListItem(
    $input: UpdatePositionShoppingListItemInput!
  ) {
    updatePositionShoppingListItem(input: $input) {
      clientMutationId
    }
  }
`;
