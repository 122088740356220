import isDefined from "@/utils/isDefined";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "@chakra-ui/next-js";
import slug from "slug";
import { Product } from "@/hooks/products/utils";
import { FC } from "react";

type ProductBreadcrumbsProps = {
  product: Product;
};

const ProductBreadcrumbs: FC<ProductBreadcrumbsProps> = ({ product }) => {
  return (
    <Breadcrumb>
      {[product.mainCategory, product.subCategory]
        .filter(isDefined)
        .map((category) => {
          if (category.id == null) {
            console.error("category.id is required");
            return null;
          }
          if (category.name == null) {
            console.error("category.name is required");
            return null;
          }
          return (
            <BreadcrumbItem key={category.id}>
              <BreadcrumbLink
                as={Link}
                prefetch={false}
                href={`/department/${slug(category.name)}/${category.id}`}
              >
                {category?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
    </Breadcrumb>
  );
};

export default ProductBreadcrumbs;
