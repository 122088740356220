import { gql } from "@apollo/client";

export const MUTATION_CREATE_SHOPPING_LIST = gql`
  mutation CreateShoppingList($input: CreateShoppingListInput!) {
    createShoppingList(input: $input) {
      shoppingList {
        id
      }
    }
  }
`;
