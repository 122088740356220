import { DeepPartial } from "@apollo/client/utilities";
import {
  Order,
  Shipment as ShipmentType,
} from "@/documents/__generated__/globalTypes.codegen";
import isDefined from "@/utils/isDefined";
import { parseOrder } from "@/hooks/cart/utils";

export type Shipment = DeepPartial<ShipmentType> & {
  id: number;
  isRecurring: boolean;
  isEditable: boolean;
  editUpTo: number | null;
  orders: ReturnType<typeof parseOrder>[];
  closestAvailableDate: number;
};

export const parseShipment = (
  shipment: DeepPartial<ShipmentType>,
): Shipment => {
  if (!shipment.id) {
    throw new Error("Shipment id is required");
  }
  const nodes: DeepPartial<Order>[] =
    shipment?.orders?.nodes?.filter(isDefined) || [];
  const orders = nodes.map((order) => parseOrder(order, shipment));
  const closestAvailableDate = Math.min(
    ...orders.map((order) => order.closestAvailableDate).filter(isDefined),
  );
  return {
    ...shipment,
    id: shipment.id,
    productsCount: shipment?.productsCount || 0,
    subTotal: shipment?.subTotal || 0,
    orders,
    closestAvailableDate,
    uniqueId: shipment.uniqueId,
    isRecurring:
      shipment?.orders?.nodes?.some((order) => order?.isRecurringOrder) ||
      false,
    isEditable:
      shipment?.orders?.nodes?.some((order) => order?.editUpTo) || false,
    editUpTo:
      shipment?.orders?.nodes?.find((order) => order?.editUpTo)?.editUpTo ||
      null,
  };
};

export const parseShipments = (
  shipments: DeepPartial<ShipmentType>[],
): Shipment[] => {
  return shipments.map(parseShipment);
};
