"use client";
import { ApolloError, useFragment, useReactiveVar } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { FRAGMENT_SHOPPING_SESSION } from "@/documents/fragments/FRAGMENT_SHOPPING_SESSION";
import { FragmentShoppingSessionFragment } from "@/documents/fragments/__generated__/FRAGMENT_SHOPPING_SESSION.codegen";
import { parseCart } from "@/hooks/cart/utils";
import { useEffect } from "react";
import { makeVar as makeLove } from "@apollo/client";

export const shoppingSessionReactiveVar = makeLove<{
  data: ReturnType<typeof parseCart> | null;
  loading: boolean;
  error?: ApolloError | null;
}>({
  data: null,
  loading: false,
});

export const useShoppingSession = (
  from: FragmentShoppingSessionFragment | null = null,
) => {
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const { data } = useFragment<
    FragmentShoppingSessionFragment,
    {
      restaurantId?: number | null;
      includeUpdatedItemsInformation: boolean;
    }
  >({
    fragment: FRAGMENT_SHOPPING_SESSION,
    fragmentName: "FragmentShoppingSession",
    variables: {
      restaurantId: sessionUser?.data?.restaurantID,
      includeUpdatedItemsInformation: true,
    },
    from,
  });

  useEffect(() => {
    if (from) {
      shoppingSessionReactiveVar({
        data: parseCart(data),
        loading: false,
      });
    }
  }, [data, from]);
};
