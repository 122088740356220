import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { useDisclosure } from "@chakra-ui/hooks";
import { forwardRef, ForwardRefRenderFunction } from "react";

type InputPasswordProps = InputProps & {};

const InputPasswordView: ForwardRefRenderFunction<
  HTMLInputElement,
  InputPasswordProps
> = (props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <InputGroup size="md">
      <Input
        {...props}
        ref={ref}
        aria-label={"Password"}
        type={isOpen ? "text" : "password"}
        placeholder="Enter password"
      />
      <InputRightElement>
        <IconButton
          variant="unstyled"
          aria-label="Toggle password visibility"
          onClick={onToggle}
          icon={<Icon as={isOpen ? BiSolidHide : BiSolidShow} />}
        />
      </InputRightElement>
    </InputGroup>
  );
};

const InputPassword = forwardRef(InputPasswordView);

export default InputPassword;
