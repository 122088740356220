import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  UseControllerReturn,
} from "react-hook-form";
import { ReactNode } from "react";
import { useCombobox } from "downshift";
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import clsx from "clsx";
import { getDollarsDisplayPrice } from "@/utils/price";
import {
  PackageTypeEnum,
  PriceLevel,
} from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";
import isDefined from "@/utils/isDefined";
import isPresent from "@/utils/isPresent";

function QuantitySelectView<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  options,
  field,
  label,
  inCart,
  isDisabled,
  onFocus,
  onBlur,
}: UseControllerReturn<TFieldValues, TName> & {
  options: Option[];
  inCart?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
}) {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    setHighlightedIndex,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      setHighlightedIndex(Number(inputValue));
    },
    selectedItem: options.find((item) => item.value === field.value) || null,
    initialHighlightedIndex: 1,
    scrollIntoView: (el) => {
      el?.scrollIntoView?.({
        block: "center",
        inline: "center",
      });
    },
    onSelectedItemChange({ selectedItem }) {
      field.onChange(selectedItem?.value);
    },
    items: options,
    itemToString(item) {
      return item ? String(item.value) : "";
    },
  });

  return (
    <FormControl isDisabled={isDisabled}>
      <FormLabel {...getLabelProps()}>{label}</FormLabel>
      <InputGroup>
        <Input
          placeholder="Select quantity"
          type="number"
          aria-label={"Select quantity"}
          min={0}
          {...getInputProps({
            onFocus,
            onBlur,
            ref: field.ref,
          })}
        />
        <InputRightElement>
          <IconButton
            aria-label="Toggle menu"
            icon={<ChevronDownIcon fontSize="20" />}
            variant="unstyled"
            {...getToggleButtonProps()}
          />
        </InputRightElement>
      </InputGroup>
      <List
        as={Grid}
        gridTemplateColumns="auto auto minmax(0, 1fr)"
        className={`absolute w-full bg-white mt-1 shadow-md max-h-80 overflow-scroll z-20 [&::-webkit-scrollbar]:w-1
            [&::-webkit-scrollbar-thumb]:bg-slate-200 ${
              !(isOpen && options.length) && "!hidden"
            }`}
        {...getMenuProps()}
      >
        {isOpen &&
          options.map((item, index) => (
            <ListItem
              as={Grid}
              gridTemplateColumns="subgrid"
              gridColumn="span 3"
              className={clsx(
                "cursor-pointer",
                highlightedIndex === index && "bg-gray-100",
                selectedItem === item && "font-bold",
                "py-2 px-3 shadow-sm flex flex-col",
              )}
              key={item.value}
              {...getItemProps({ item, index })}
            >
              <Grid
                gap={3}
                gridTemplateColumns="subgrid"
                gridColumn="span 3"
                alignItems="center"
              >
                {item.value === 0 ? (
                  <>
                    <Grid
                      gridTemplateColumns="subgrid"
                      gridColumn="span 3"
                      gap={3}
                    >
                      <GridItem>0</GridItem>
                      {inCart && (
                        <GridItem colSpan={2} fontSize="sm" color="gray.500">
                          Remove from cart
                        </GridItem>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <GridItem>{item.value}</GridItem>
                    <GridItem fontSize="sm" color="gray.500">
                      {getDollarsDisplayPrice(item.discountedPricePerQuantity)}
                    </GridItem>
                    {item.percentage ? (
                      <GridItem>
                        <Flex gap={2}>
                          <Text
                            className="line-through"
                            fontSize="sm"
                            as="span"
                            color="gray.500"
                          >
                            {getDollarsDisplayPrice(item.basePricePerQuantity)}
                          </Text>
                          <Text as="span" fontSize="sm" color="blue.500">
                            (Save {item.percentage}%)
                          </Text>
                        </Flex>
                      </GridItem>
                    ) : null}
                  </>
                )}
              </Grid>
            </ListItem>
          ))}
      </List>
    </FormControl>
  );
}

export type VariantOption = {
  packageType: PackageTypeEnum;
  packageTypeTitle: string;
  quantity?: number | null;
  basePriceLevel: DeepPartial<PriceLevel>;
  volumeDiscountedPriceLevel?: DeepPartial<PriceLevel>;
  orderItemId?: number;
  weight?: number | null;
  catchweightItem?: boolean;
  itemsCount?: number;
};

type QuantitySelectProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  "render"
> & {
  variant: VariantOption & {
    options: Option[];
  };
  inCart?: boolean;
  label?: ReactNode;
  isDisabled?: boolean;
  onFocus: () => void;
  onBlur: () => void;
};

function QuantitySelect<T extends FieldValues>({
  label,
  variant,
  isDisabled,
  inCart,
  onFocus,
  onBlur,
  ...props
}: QuantitySelectProps<T>) {
  return (
    <Controller
      {...props}
      render={(props) => (
        <QuantitySelectView
          {...props}
          inCart={inCart}
          onFocus={onFocus}
          onBlur={onBlur}
          isDisabled={isDisabled}
          options={variant.options}
          label={label}
        />
      )}
    />
  );
}

const getMinimalPrice = (...levels: DeepPartial<PriceLevel>[]) => {
  return Math.min(
    ...levels
      ?.filter(isDefined)
      .map((level) => {
        return [level?.price, level?.discountedPrice].filter(isPresent);
      })
      .flat(),
  );
};

const getPrices = (
  price: number,
  basePrice: number,
  quantity: number,
  discountedPrice?: number | null,
) => {
  const basePricePerQuantity = basePrice * quantity;
  const discountedPricePerQuantity = (discountedPrice || price) * quantity;
  const diff = basePricePerQuantity - discountedPricePerQuantity;
  const percentage =
    basePrice - (discountedPrice || price) &&
    Math.ceil(diff / (basePricePerQuantity / 100));

  return {
    basePricePerQuantity,
    discountedPricePerQuantity,
    percentage,
  };
};

const createListItem = (
  quantity: number,
  priceLevel: DeepPartial<PriceLevel>,
  basePriceLevel: DeepPartial<PriceLevel>,
) => {
  const basePrice = getMinimalPrice(basePriceLevel);
  const { price, discountedPrice } = priceLevel;

  if (price == null) {
    throw new Error("Price is required");
  }

  const { discountedPricePerQuantity, percentage, basePricePerQuantity } =
    getPrices(price, basePrice, quantity, discountedPrice);

  return {
    value: quantity,
    discountedPricePerQuantity,
    percentage,
    basePricePerQuantity,
  };
};

export const getZeroOption = (): Option => {
  return {
    value: 0,
  };
};

export type Option = {
  discountedPricePerQuantity?: number;
  percentage?: number;
  basePricePerQuantity?: number;
  value: number;
};

export const getPriceLevelByQuantity = ({
  volumeDiscountedPriceLevel,
  basePriceLevel,
  quantity,
}: {
  quantity?: number | null;
  basePriceLevel: DeepPartial<PriceLevel>;
  volumeDiscountedPriceLevel?: DeepPartial<PriceLevel>;
}) => {
  if (
    quantity &&
    volumeDiscountedPriceLevel &&
    volumeDiscountedPriceLevel.quantity &&
    quantity >= volumeDiscountedPriceLevel.quantity
  ) {
    return volumeDiscountedPriceLevel;
  }

  return basePriceLevel;
};

const MAX_QUANTITY = 101;

export const buildOptions = (
  variant: VariantOption,
  includeZeroOption: boolean = true,
): Option[] => {
  const { basePriceLevel } = variant;
  const options = [];
  let i = 1;

  if (includeZeroOption) {
    options.push(getZeroOption());
  }

  while (i < MAX_QUANTITY) {
    const priceLevel = getPriceLevelByQuantity({
      basePriceLevel,
      volumeDiscountedPriceLevel: variant.volumeDiscountedPriceLevel,
      quantity: i,
    });

    const option = createListItem(i, priceLevel, basePriceLevel);
    options.push(option);

    i++;
  }

  return options;
};

export default QuantitySelect;
