import useToastedMutation from "@/hooks/toasted/useToastedMutation";
import { MUTATION_IMPORT_FROM_PREVIOUS_SHOPPING_SESSION } from "@/documents/MUTATION_IMPORT_FROM_PREVIOUS_SHOPPING_SESSION";
import {
  ImportFromPreviousShoppingSessionMutation,
  ImportFromPreviousShoppingSessionMutationVariables,
} from "@/documents/__generated__/MUTATION_IMPORT_FROM_PREVIOUS_SHOPPING_SESSION.codegen";
import { useCallback } from "react";
import { ImportItemsFromPreviousShoppingSessionInput } from "@/documents/__generated__/globalTypes.codegen";

export const useImportFromPreviousShoppingSession = () => {
  const [mutate, { loading }] = useToastedMutation<
    ImportFromPreviousShoppingSessionMutation,
    ImportFromPreviousShoppingSessionMutationVariables
  >(MUTATION_IMPORT_FROM_PREVIOUS_SHOPPING_SESSION);

  const importFromPreviousShoppingSession = useCallback(
    (input: ImportItemsFromPreviousShoppingSessionInput) => {
      mutate({
        variables: {
          input,
          restaurantId: input.restaurantId,
        },
      });
    },
    [mutate],
  );

  return {
    importFromPreviousShoppingSession,
    loading,
  };
};
