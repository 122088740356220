"use client";
import { Box, ChakraProps, ComponentWithAs } from "@chakra-ui/react";
import { useReactiveVar } from "@apollo/client";
import { signInDisclosureReactiveVar } from "../SignInModal";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { MouseEventHandler, useCallback } from "react";

const SignInGate: ComponentWithAs<typeof Box, ChakraProps> = (props) => {
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const disclosure = useReactiveVar(signInDisclosureReactiveVar);
  const { onClick, as } = props;

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      if (!sessionUser?.data) {
        if (!disclosure) {
          throw new Error(
            "SignInGate requires SignIn component to be rendered",
          );
        }
        event.preventDefault();
        disclosure.onOpen();
      } else {
        onClick?.(event);
      }
    },
    [disclosure, onClick, sessionUser?.data],
  );

  return (
    <Box
      as={as}
      {...props}
      onClickCapture={handleClick}
      onClick={handleClick}
    />
  );
};

export default SignInGate;
