import { Box, Heading, HStack, Tag, Text } from "@chakra-ui/react";
import { Product } from "@/hooks/products/utils";
import { FC } from "react";

type ProductHeaderProps = {
  product: Product;
};

const ProductHeader: FC<ProductHeaderProps> = ({ product }) => {
  return (
    <>
      <Box>
        <Heading size="md" as="h1">
          {product.catalogName}
        </Heading>
        <Text fontSize="md" color="gray.500">
          {product.brand}
        </Text>
      </Box>
      {product.specs.length ? (
        <HStack>
          {product.specs.map(({ id, name }) => (
            <Tag colorScheme="blue" key={id}>
              {name}
            </Tag>
          ))}
        </HStack>
      ) : null}
    </>
  );
};

export default ProductHeader;
