import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";

export const FRAGMENT_ORDER_ITEM = gql`
  ${FRAGMENT_PRODUCT}
  fragment FragmentOrderItem on OrderItem {
    id
    quantity
    subTotal
    orderId
    price
    packagingType
    product {
      ...FragmentProduct
    }
  }
`;
