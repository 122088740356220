import { gql } from "@apollo/client";

export const MUTATION_UPDATE_SHOPPING_LIST = gql`
  mutation UpdateShoppingList($input: UpdateShoppingListInput!) {
    updateShoppingList(input: $input) {
      shoppingList {
        id
      }
    }
  }
`;
