const DEFAULT_LOCALE = "en-us";

export const formatTime = (hours: number) =>
  new Intl.DateTimeFormat("en-US", { hour12: true, hour: "numeric" })
    .format(new Date(0, 0, 0, hours))
    .replace(" ", ""); // 3 AM -> 3AM

export const getTimeRangeValues = (value: { start: number; end: number }) => [
  formatTime(value.start),
  formatTime(value.end),
];

export const getTimeRange = (value: { start: number; end: number }) =>
  getTimeRangeValues(value).join(" - ");

export const getShortDateFromEpoch = (utcSeconds: number | null): string => {
  if (utcSeconds === null) {
    return "";
  }

  return getFullDateFromEpoch(utcSeconds).toLocaleString(DEFAULT_LOCALE, {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
};

export const getFullDateFromEpoch = (utcSeconds: number): Date => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  return d;
};

const tryGetDateShorthand = (date: Date) => {
  if (isToday(date)) {
    return "today";
  }
  if (isTomorrow(date)) {
    return "tomorrow";
  }
  if (isPastDate(date) && isYesterday(date)) {
    return "yesterday";
  }
};

export const humanizeDate = (
  utcSeconds: number,
  options: Intl.DateTimeFormatOptions & {
    pretty?: boolean;
  } = {
    month: "short",
    day: "numeric",
    pretty: true,
  },
): string => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  if (!options.pretty) {
    return d.toLocaleString(DEFAULT_LOCALE, options);
  }
  const shorthand = tryGetDateShorthand(d);
  if (shorthand) {
    return shorthand;
  }
  return d.toLocaleString(DEFAULT_LOCALE, options);
};

// TODO: check if dayJS has this functionality and timeZone support
const isTomorrow = (date: Date): boolean => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toDateString() === date.toDateString();
};

const isToday = (date: Date) => {
  const today = new Date();
  return today.toDateString() === date.toDateString();
};

const isYesterday = (date: Date) => {
  const diffTime = Math.abs(Date.now() - date.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays === 1;
};

export const isPastDate = (date: Date) => {
  return Date.now() > date.getTime();
};

export const epochToISODateString = (date: number) =>
  new Date(date * 1000).toISOString();

export const getWeekday = (day: number) => {
  return new Intl.DateTimeFormat(DEFAULT_LOCALE, { weekday: "long" }).format(
    new Date(0, 0, day),
  );
};
