import { useReactiveVar } from "@apollo/client";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import {
  PackageTypeEnum,
  Product as ProductType,
} from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";
import { parseProduct } from "@/hooks/products/utils";
import { useMemo } from "react";

export const useShoppingSessionProduct = (
  product: DeepPartial<ProductType>,
  packageType?: PackageTypeEnum,
) => {
  const cart = useReactiveVar(shoppingSessionReactiveVar);
  const lists = useReactiveVar(shoppingListsReactiveVar);

  return {
    product: useMemo(
      () =>
        parseProduct(product, {
          cart: cart.data,
          lists,
          packageType,
        }),
      [packageType, cart, lists, product],
    ),
    loading: cart.loading,
  };
};
