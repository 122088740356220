"use client";
import { useQuery, useReactiveVar } from "@apollo/client";
import { QUERY_RECOMMENDATIONS } from "@/documents/QUERY_RECOMMENDATIONS";
import {
  RecommendationsQuery,
  RecommendationsQueryVariables,
} from "@/documents/__generated__/QUERY_RECOMMENDATIONS.codegen";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { useMemo } from "react";
import { shoppingSessionReactiveVar } from "@/hooks/session/useShoppingSession";
import { InvalidTokenError } from "@/app/lib/utils";
import { isApolloError } from "@apollo/client/errors";
import { isServerParseError } from "@/apollo/utils";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";

export const useRecommendations = (
  variables: RecommendationsQueryVariables,
) => {
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const { data, loading } = useQuery<
    RecommendationsQuery,
    RecommendationsQueryVariables
  >(QUERY_RECOMMENDATIONS, {
    variables: {
      ...variables,
      ...(sessionUser?.data?.restaurantID && {
        restaurantId: sessionUser?.data?.restaurantID,
      }),
    },
    onError: (error) => {
      if (
        (isApolloError(error) &&
          isServerParseError(error?.networkError) &&
          (error.networkError.statusCode === 400 ||
            error.networkError.statusCode === 401)) ||
        error.graphQLErrors.some((e) => e.extensions?.code === "not_found")
      ) {
        shoppingSessionReactiveVar({
          data: null,
          loading: false,
        });
        sessionUserReactiveVar({
          error: InvalidTokenError,
          loading: false,
        });
        shoppingListsReactiveVar(null);
      } else {
        console.error(error);
      }
    },
  });

  const products = useMemo(
    () => data?.restaurant?.recommendations?.map(({ product }) => product),
    [data],
  );

  return {
    products,
    loading,
  };
};
