"use client";
import Link from "next/link";
import { DeepPartial } from "@apollo/client/utilities";
import { Vendor } from "@/documents/__generated__/globalTypes.codegen";
import { FC } from "react";
import Image from "next/image";

type HomeProps = {
  vendor?: DeepPartial<Vendor>;
};

const Home: FC<HomeProps> = ({ vendor }) => {
  return (
    <Link
      href="/"
      prefetch={false}
      className="relative h-full w-24 lg:w-40"
      title="Homepage"
    >
      <Image
        src={vendor?.image?.url || "/img/logo.png"}
        priority
        alt={vendor?.name || "Kimelo"}
        sizes="auto"
        fill
        className="object-contain"
      />
    </Link>
  );
};

export default Home;
