import {
  Box,
  CardProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBoolean,
} from "@chakra-ui/react";
import clsx from "clsx";
import Image from "next/image";
import { FC } from "react";
import { Product } from "@/hooks/products/utils";

type ImageSwitchProps = {
  product: Product;
  size: CardProps["size"];
};

const ImageSwitch: FC<ImageSwitchProps> = ({ product, size }) => {
  const [loaded, { on }] = useBoolean();
  return (
    <Tabs variant="unstyled">
      <TabPanels>
        {product?.gallery?.map((image, index) => (
          <TabPanel key={index}>
            <Box
              className={clsx("relative", {
                ["h-64"]: size === "sm",
                ["h-80"]: size === "md",
              })}
            >
              <Image
                onLoad={on}
                // TODO(@oleksii.a): remove the non-null assertion operator
                src={image.hqUrl!}
                priority={index === 0}
                alt={product.catalogName}
                quality={60}
                sizes="auto"
                fill
                className={clsx("object-contain transition-opacity opacity-0", {
                  ["opacity-100"]: loaded,
                })}
              />
            </Box>
          </TabPanel>
        ))}
      </TabPanels>
      <TabList flexWrap="wrap" gap={2} className="mt-2">
        {product?.gallery?.map((image, index) => (
          <Tab
            className="border"
            _selected={{
              borderColor: "gray",
            }}
            key={index}
          >
            <Box className="relative w-10 h-10">
              <Image
                src={image.url!}
                alt={product.catalogName}
                fill
                sizes="auto"
                className={clsx("object-contain transition-opacity opacity-0", {
                  ["opacity-100"]: loaded,
                })}
              />
            </Box>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default ImageSwitch;
