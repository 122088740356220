"use client";
import { FC, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import SignInModal from "@/components/SignInModal";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import Restaurants from "@/components/Restaurants";
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Skeleton,
  useBreakpointValue,
} from "@chakra-ui/react";
import ListNav from "@/components/Lists/ListNav";
import { usePathname } from "next/navigation";
import CartContainer from "@/containers/cart";

type DisplayOptions = {
  restaurant?: boolean;
  list?: boolean;
  cart?: boolean;
  auth?: boolean;
};

const DISPLAY_OPTIONS_DEFAULTS: DisplayOptions = {
  restaurant: true,
  list: true,
  cart: true,
  auth: true,
};

const getDisplayOptions = (displayOptions?: Partial<DisplayOptions>) => {
  return {
    ...DISPLAY_OPTIONS_DEFAULTS,
    ...displayOptions,
  };
};

const ShoppingControls: FC = () => {
  const pathname = usePathname();
  const options = useMemo(() => {
    if (pathname.includes("checkout")) {
      return {
        cart: false,
        list: false,
        auth: false,
      };
    }
    if (pathname.includes("lists")) {
      return {
        list: false,
        auth: false,
      };
    }
  }, [pathname]);
  const displayOptions = getDisplayOptions(options);
  const sessionUser = useReactiveVar(sessionUserReactiveVar);

  const size = useBreakpointValue<ButtonGroupProps["size"]>(
    {
      base: "sm",
      lg: "md",
    },
    {
      fallback: "lg",
    },
  );

  return sessionUser?.data ? (
    <ButtonGroup maxW="100%" size={size}>
      {displayOptions.restaurant && <Restaurants user={sessionUser.data} />}
      {displayOptions.list && <ListNav user={sessionUser.data} />}
      {displayOptions.cart && <CartContainer user={sessionUser.data} />}
    </ButtonGroup>
  ) : (
    displayOptions.auth && (
      <SignInModal error={sessionUser?.error}>
        {({ onOpen }) => (
          <Skeleton isLoaded={!sessionUser?.loading}>
            <Button
              aria-label="Sign in"
              aria-haspopup="dialog"
              colorScheme="midnight_navy"
              onClick={onOpen}
              size={size}
            >
              Sign in
            </Button>
          </Skeleton>
        )}
      </SignInModal>
    )
  );
};

export default ShoppingControls;
