"use client";
import { useLazyQuery } from "@apollo/client";
import { QUERY_SHOPPING_LISTS } from "@/documents/QUERY_SHOPPING_LISTS";
import {
  ShoppingListsQuery,
  ShoppingListsQueryVariables,
} from "@/documents/__generated__/QUERY_SHOPPING_LISTS.codegen";
import { useCallback, useEffect, useMemo } from "react";
import { parseShoppingLists } from "@/hooks/lists/utils";
import { makeVar as makeLove } from "@apollo/client";

export const shoppingListsReactiveVar = makeLove<ReturnType<
  typeof parseShoppingLists
> | null>(null);

export const useShoppingLists = () => {
  const [query, { data, loading }] = useLazyQuery<
    ShoppingListsQuery,
    ShoppingListsQueryVariables
  >(QUERY_SHOPPING_LISTS);

  const load = useCallback(
    (variables: ShoppingListsQueryVariables) => {
      return query({
        variables,
        fetchPolicy: "cache-first",
      });
    },
    [query],
  );

  const lists = useMemo(() => {
    if (data) {
      return parseShoppingLists(data);
    }
  }, [data]);
  useEffect(() => {
    if (lists) {
      shoppingListsReactiveVar(lists);
    }
  }, [lists]);

  return {
    ...lists,
    load,
    loading,
  };
};
