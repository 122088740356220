import useToastedMutation, {
  ToastPromiseOptions,
} from "@/hooks/toasted/useToastedMutation";
import { MUTATION_DESTROY_SHOPPING_LIST_ITEM } from "@/documents/MUTATION_DESTROY_SHOPPING_LIST_ITEM";
import { useCallback } from "react";
import { QUERY_SHOPPING_LISTS } from "@/documents/QUERY_SHOPPING_LISTS";
import {
  CreateShoppingListItemInput,
  DestroyShoppingListItemInput,
  DestroyShoppingListItemsInput,
  MoveShoppingListItemsInput,
  UpdatePositionShoppingListItemInput,
} from "@/documents/__generated__/globalTypes.codegen";
import {
  DestroyShoppingListItemMutation,
  DestroyShoppingListItemMutationVariables,
} from "@/documents/__generated__/MUTATION_DESTROY_SHOPPING_LIST_ITEM.codegen";
import { MUTATION_CREATE_SHOPPING_LIST_ITEM } from "@/documents/MUTATION_CREATE_SHOPPING_LIST_ITEM";
import {
  CreateShoppingListItemMutation,
  CreateShoppingListItemMutationVariables,
} from "@/documents/__generated__/MUTATION_CREATE_SHOPPING_LIST_ITEM.codegen";
import { FetchResult, MaybeMasked, useReactiveVar } from "@apollo/client";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import { MUTATION_MOVE_SHOPPING_LIST_ITEMS } from "@/documents/MUTATION_MOVE_SHOPPING_LIST_ITEMS";
import {
  MoveShoppingListItemMutation,
  MoveShoppingListItemMutationVariables,
} from "@/documents/__generated__/MUTATION_MOVE_SHOPPING_LIST_ITEMS.codegen";
import { MUTATION_UPDATE_POSITION_SHOPPING_LIST_ITEM } from "@/documents/MUTATION_UPDATE_POSITION_SHOPPING_LIST_ITEM";

const getToastPromiseOptions = <TData>(): ToastPromiseOptions<
  FetchResult<MaybeMasked<TData>>
> => ({
  success: () => ({
    title: `List updated`,
    description: `Item updated`,
    status: "success",
  }),
  error: ({ message }) => ({
    title: "List update failed",
    description: message,
    status: "error",
  }),
  loading: {
    title: "Updating list item",
    description: "Please wait...",
  },
});

export const useUpdateShoppingList = () => {
  const lists = useReactiveVar(shoppingListsReactiveVar);
  const [remove, { loading: destroying }] = useToastedMutation<
    DestroyShoppingListItemMutation,
    DestroyShoppingListItemMutationVariables
  >(MUTATION_DESTROY_SHOPPING_LIST_ITEM, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions:
      getToastPromiseOptions<DestroyShoppingListItemMutation>(),
  });
  const [add, { loading: creating }] = useToastedMutation<
    CreateShoppingListItemMutation,
    CreateShoppingListItemMutationVariables
  >(MUTATION_CREATE_SHOPPING_LIST_ITEM, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions:
      getToastPromiseOptions<CreateShoppingListItemMutation>(),
  });
  const [transfer, { loading: moving }] = useToastedMutation<
    MoveShoppingListItemMutation,
    MoveShoppingListItemMutationVariables
  >(MUTATION_MOVE_SHOPPING_LIST_ITEMS, {
    refetchQueries: [QUERY_SHOPPING_LISTS],
    toastPromiseOptions: getToastPromiseOptions<MoveShoppingListItemMutation>(),
  });
  const [position, { loading: reordering }] = useToastedMutation(
    MUTATION_UPDATE_POSITION_SHOPPING_LIST_ITEM,
    {
      refetchQueries: [QUERY_SHOPPING_LISTS],
      toastPromiseOptions:
        getToastPromiseOptions<MoveShoppingListItemMutation>(),
    },
  );
  const destroy = useCallback(
    (input: DestroyShoppingListItemsInput) => {
      return remove({ variables: { input } });
    },
    [remove],
  );
  const create = useCallback(
    (input: CreateShoppingListItemInput) => {
      return add({ variables: { input } });
    },
    [add],
  );

  const move = useCallback(
    (input: MoveShoppingListItemsInput) => {
      return transfer({ variables: { input } });
    },
    [transfer],
  );

  const toggle = useCallback(
    (input: CreateShoppingListItemInput | DestroyShoppingListItemInput) => {
      if (lists?.inList(input.productId, input.shoppingListId)) {
        return destroy({
          shoppingListId: input.shoppingListId,
          productIds: [input.productId],
        });
      } else {
        return create(input);
      }
    },
    [lists, create, destroy],
  );
  const reorder = useCallback(
    (input: UpdatePositionShoppingListItemInput) => {
      return position({ variables: { input } });
    },
    [position],
  );

  return {
    toggle,
    create,
    creating,
    destroy,
    destroying,
    toggling: creating || destroying,
    move,
    moving,
    reorder,
    reordering,
  };
};
