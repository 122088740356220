import { useLazyQuery } from "@apollo/client";
import { QUERY_MOVE_ITEMS_DELIVERIES } from "@/documents/QUERY_MOVE_ITEMS_DELIVERIES";
import { useCallback, useMemo } from "react";
import {
  MoveItemsDeliveriesQuery,
  MoveItemsDeliveriesQueryVariables,
} from "@/documents/__generated__/QUERY_MOVE_ITEMS_DELIVERIES.codegen";
import { ShoppingSessionDelivery } from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";

export const useMoveItemsDeliveries = () => {
  const [query, { data, loading }] = useLazyQuery<
    MoveItemsDeliveriesQuery,
    MoveItemsDeliveriesQueryVariables
  >(QUERY_MOVE_ITEMS_DELIVERIES, {
    fetchPolicy: "no-cache",
  });

  const moveItemsDeliveries = useCallback(
    (restaurantID: number) => {
      return query({
        variables: {
          restaurantId: restaurantID,
        },
      });
    },
    [query],
  );

  const deliveries: DeepPartial<ShoppingSessionDelivery>[] = useMemo(
    () => data?.restaurant.currentShoppingSession.moveItemsDeliveries || [],
    [data],
  );

  return {
    moveItemsDeliveries,
    loading,
    deliveries,
  };
};
