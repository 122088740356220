import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Select,
  Button,
} from "@chakra-ui/react";
import { FC } from "react";
import { UseDisclosureReturn } from "@chakra-ui/hooks/use-disclosure";
import { DeepPartial } from "@apollo/client/utilities";
import { Address } from "@/documents/__generated__/globalTypes.codegen";
import { Controller, useForm } from "react-hook-form";
import { PlacesAutocompleteInput } from "@/components/PlacesAutocompleteInput";
import { parseAddress } from "@/components/PlacesAutocompleteInput/utils";
import { STATES } from "@/components/SignUpForm/CreateRestaurant";

type UpdateRestaurantModalProps = UseDisclosureReturn & {
  currentAddress: DeepPartial<Address>;
  onSubmit: (address: DeepPartial<Address>) => void;
};

const UpdateRestaurantModal: FC<UpdateRestaurantModalProps> = ({
  isOpen,
  onClose,
  currentAddress,
  onSubmit,
}) => {
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DeepPartial<Address>>({
    defaultValues: currentAddress,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enter your address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="stretch" gap={4}>
            <FormControl isRequired isInvalid={Boolean(errors.addressLine)}>
              <FormLabel htmlFor="addressLine">Address</FormLabel>
              <Controller
                rules={{ required: "Field is required" }}
                render={({ field }) => {
                  return (
                    <PlacesAutocompleteInput
                      {...field}
                      isInvalid={Boolean(errors.addressLine)}
                      isRequired
                      request={{
                        types: ["address"],
                      }}
                      onSelectPlace={(suggestion, placeResult) => {
                        if (placeResult) {
                          const address = parseAddress(placeResult);
                          reset({
                            addressLine: suggestion.title,
                            city: address.city,
                            state: address.state,
                            zipcode: address.postalCode,
                          });
                        }
                      }}
                      id="addressLine"
                    />
                  );
                }}
                name="addressLine"
                control={control}
              />
            </FormControl>
            <FormControl isRequired isInvalid={Boolean(errors.city)}>
              <FormLabel>City</FormLabel>
              <Input
                autoComplete="off"
                {...register("city", {
                  required: true,
                })}
              />
            </FormControl>
            <HStack>
              <FormControl isRequired isInvalid={Boolean(errors.zipcode)}>
                <FormLabel>Zipcode</FormLabel>
                <Input
                  autoComplete="off"
                  {...register("zipcode", {
                    required: true,
                  })}
                />
              </FormControl>
              <FormControl isRequired isInvalid={Boolean(errors.state)}>
                <FormLabel>State</FormLabel>
                <Select
                  {...register("state", {
                    required: true,
                  })}
                  placeholder="Select state"
                >
                  {STATES.map((state) => (
                    <option key={state.value} value={state.shorthand}>
                      {state.value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            form="address"
            colorScheme="midnight_navy"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateRestaurantModal;
