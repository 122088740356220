import { DeepPartial } from "@apollo/client/utilities";
import {
  Image,
  Product,
  ShoppingList as ShoppingListType,
  ShoppingListItem as ShoppingListItemType,
} from "@/documents/__generated__/globalTypes.codegen";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import toPairs from "lodash/toPairs";
import { ShoppingListsQuery } from "@/documents/__generated__/QUERY_SHOPPING_LISTS.codegen";
import { parseProduct } from "@/hooks/products/utils";
import isDefined from "@/utils/isDefined";

export enum SortKeys {
  MOST_RECENT = "Most recent delivered",
  LEAST_RECENT = "Least recent delivered",
  CATEGORY = "Category",
  LOWEST_PRICE = "Price: Lowest first",
  HIGHEST_PRICE = "Price: Highest first",
  A_TO_Z = "Alphabetical: A to Z",
  Z_TO_A = "Alphabetical: Z to A",
  CUSTOM = "Custom order",
}

export const sortByCategory = (items: ShoppingListItem[]) => {
  return new Map(
    toPairs(
      groupBy(
        orderBy(items, (item) => item.product?.groupedByCategoryName),
        (item) => item.product?.groupedByCategoryName,
      ),
    ),
  );
};

export const sortByName = (
  items: ShoppingListItem[],
  ascending: boolean = false,
) => {
  return new Map(
    toPairs(
      groupBy(
        orderBy(
          items,
          (item) => item.product?.catalogName,
          ascending ? "asc" : "desc",
        ),
        (item) => item.product?.catalogName?.[0],
      ),
    ),
  );
};

export const sortByPrice = (
  items: ShoppingListItem[],
  ascending: boolean = false,
) => {
  return new Map([
    [
      null,
      orderBy(
        items,
        (item) => {
          if (!item.product) {
            throw new Error("Product is required");
          }
          const { baseVariant } = parseProduct(item.product);
          return baseVariant?.price?.price;
        },
        ascending ? "asc" : "desc",
      ),
    ],
  ]);
};

export const sortByPosition = (items: ShoppingListItem[]) => {
  return new Map([[null, orderBy(items, (item) => item.position, "asc")]]);
};

export const sortByLastDelivery = (
  items: ShoppingListItem[],
  ascending: boolean = false,
) => {
  return new Map([
    [
      null,
      orderBy(
        items,
        (item) => item.lastDeliveryInformation?.date,
        ascending ? "asc" : "desc",
      ),
    ],
  ]);
};

export type ListItemsBySortKey = Map<string | null, ShoppingListItem[]>;

export const getItemsBySortKey = (
  items: ShoppingListItem[],
  sortBy: SortKeys,
): ListItemsBySortKey => {
  switch (sortBy) {
    case SortKeys.A_TO_Z:
      return sortByName(items, true);
    case SortKeys.Z_TO_A:
      return sortByName(items);
    case SortKeys.HIGHEST_PRICE:
      return sortByPrice(items);
    case SortKeys.LOWEST_PRICE:
      return sortByPrice(items, true);
    case SortKeys.CUSTOM:
      return sortByPosition(items);
    case SortKeys.MOST_RECENT:
      return sortByLastDelivery(items);
    case SortKeys.LEAST_RECENT:
      return sortByLastDelivery(items, true);
    default:
      return sortByCategory(items);
  }
};

export const getItemsBySearchTerm = (
  items: ShoppingListItem[],
  searchTerm: string,
) => {
  return items.filter((item) => {
    if (item.product) {
      const lowerCase = searchTerm.toLowerCase();
      const catalogName = item.product.catalogName?.toLowerCase();
      const brand = item.product.brand?.toLowerCase();
      return catalogName?.includes(lowerCase) || brand?.includes(lowerCase);
    }
  });
};

type ShoppingListItem = DeepPartial<ShoppingListItemType> & {
  position: number;
  id: number;
};

const parseShoppingListItem = (
  node: DeepPartial<ShoppingListItemType>,
): ShoppingListItem => {
  if (!node.id) {
    throw new Error("Shopping list item ID is required");
  }

  return {
    ...node,
    id: node.id,
    position: node.position || 0,
  };
};

export type ShoppingList = DeepPartial<ShoppingListType> & {
  id: number;
  color: string;
  position: number;
  productsCount: number;
  products: DeepPartial<Product>[];
  gallery: Image[];
  shoppingListItems?: (Omit<DeepPartial<ShoppingListItem>, "position"> & {
    id: number;
    position: number;
  })[];
  shoppingListItemsForCleanup?: DeepPartial<ShoppingListItem>[];
};

export const parseShoppingList = (
  node: DeepPartial<ShoppingListType>,
): ShoppingList => {
  const products =
    node.shoppingListItems?.nodes
      ?.map((item) => item?.product)
      .filter(isDefined) || [];

  if (!node.id) {
    throw new Error("Shopping list ID is required");
  }

  return {
    ...node,
    id: node.id,
    position: node.position || 0,
    color: node.color || "#9d562e",
    productsCount: products.length,
    products,
    gallery: products
      .map((product) => parseProduct(product).image)
      .filter(isDefined),
    shoppingListItems: node.shoppingListItems?.nodes
      ?.filter(isDefined)
      .map(parseShoppingListItem),
    shoppingListItemsForCleanup: node.shoppingListItemsForCleanUp?.nodes
      ?.filter(isDefined)
      ?.map(parseShoppingListItem),
  };
};

export const parseShoppingLists = (data: ShoppingListsQuery) => {
  const items = data.restaurant.shoppingLists.nodes?.filter(isDefined) || [];
  const byIDs = data.restaurant.byIDs?.nodes?.filter(isDefined) || [];
  const lists = items
    .map(parseShoppingList)
    .sort(({ position: a }, { position: b }) => {
      return a - b;
    });
  const listsByID = byIDs.map(parseShoppingList);
  const productsCount = lists.reduce(
    (acc, list) => acc + (list.productsCount || 0),
    0,
  );
  const isFavorite = (sku?: string) => {
    return lists.some((list) =>
      list.products.some((product) => product?.sku === sku),
    );
  };
  const inList = (id: number, listID?: number) => {
    return lists.some(
      (list) =>
        list.id === listID &&
        list.products.some((product) => product?.id === id),
    );
  };
  return {
    lists,
    listsByID,
    productsCount,
    isFavorite,
    inList,
  };
};
