import { gql } from "@apollo/client";

export const MUTATION_UPDATE_POSITION_SHOPPING_LIST = gql`
  mutation UpdatePositionShoppingList(
    $input: UpdatePositionShoppingListInput!
  ) {
    updatePositionShoppingList(input: $input) {
      clientMutationId
    }
  }
`;
