import { gql } from "@apollo/client";
import { FRAGMENT_RESTAURANT } from "@/documents/fragments/FRAGMENT_RESTAURANT";

export const MUTATION_UPDATE_RESTAURANT = gql`
  ${FRAGMENT_RESTAURANT}
  mutation UpdateRestaurant($input: UpdateRestaurantInput!) {
    updateRestaurant(input: $input) {
      restaurant {
        ...FragmentRestaurant
      }
    }
  }
`;
