import { gql } from "@apollo/client";
import { FRAGMENT_ORDER_ITEM } from "@/documents/fragments/FRAGMENT_ORDER_ITEM";

export const FRAGMENT_DELIVERY = gql`
  ${FRAGMENT_ORDER_ITEM}
  fragment FragmentDelivery on ShoppingSessionDelivery {
    id
    productsCount
    deliveryDate
    deliveryStart
    deliveryEnd
    deliveryWindowId
    deliveryFee
    subTotal
    freeShortage
    fees {
      amount
      title
      type
    }
    availableDeliveryTimes {
      nodes {
        id
        price
        date
        start
        end
        customerFacingMessage
      }
    }
    orders {
      nodes {
        id
        restaurant {
          id
          name
        }
        orderItems {
          nodes {
            ...FragmentOrderItem
          }
        }
      }
    }
  }
`;
