import { FC, Suspense } from "react";
import { useProduct } from "@/hooks/products/useProduct";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ProductDetailsSkeleton from "@/components/ProductList/ProductDetails/Skeleton";
import ProductDetails from "@/components/ProductList/ProductDetails";
import { Link } from "@chakra-ui/next-js";
import { UseDisclosureReturn } from "@chakra-ui/hooks/use-disclosure";
import { DeepPartial } from "@apollo/client/utilities";
import { Product } from "@/documents/__generated__/globalTypes.codegen";
import { ErrorBoundary } from "react-error-boundary";
import slug from "slug";
import fallbackRender from "@/components/FallbackRender";

type ProductModalBodyProps = {
  sku: string;
  onUpdate: () => void;
};

const ProductModalBody: FC<ProductModalBodyProps> = ({ sku, onUpdate }) => {
  const product = useProduct(sku);

  return (
    <ProductDetails
      includeName={false}
      size="sm"
      includeRecommendations={false}
      includeDescription={false}
      product={product}
      onUpdate={onUpdate}
    />
  );
};

type ProductModalProps = UseDisclosureReturn & {
  product?: DeepPartial<Product>;
};

const ProductModal: FC<ProductModalProps> = ({ isOpen, onClose, product }) => {
  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {product?.catalogName}
          <Text fontSize="md" color="gray.500">
            {product?.brand}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {product && product.sku && (
            <Suspense
              fallback={
                <ProductDetailsSkeleton
                  includeName={false}
                  includeDescription={false}
                  includeRecommendations={false}
                  size="sm"
                />
              }
            >
              <ErrorBoundary fallbackRender={fallbackRender}>
                <ProductModalBody sku={product.sku} onUpdate={onClose} />
              </ErrorBoundary>
            </Suspense>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={onClose}>Close</Button>
            <Button
              variant="ghost"
              as={Link}
              prefetch={false}
              href={`/sku/${slug(product?.catalogName || "")}/${product?.sku}`}
            >
              View more details
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductModal;
