"use client";
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { BiBookmark, BiSolidBookmark } from "react-icons/bi";
import { FC, useEffect, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import { shoppingListsReactiveVar } from "@/hooks/lists/useShoppingLists";
import { useUpdateShoppingList } from "@/hooks/products/useUpdateShoppingList";
import { useShoppingList } from "@/hooks/lists/useShoppingList";
import { useDisclosure } from "@chakra-ui/hooks";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import SimpleCreateForm from "@/components/Lists/Create/Simple";
import { useBoolean } from "@chakra-ui/icons";

type ListMenuProps = {
  sku: string;
  id: number;
  onToggle?: (inList: boolean) => void;
};

const ListMenu: FC<ListMenuProps> = ({ sku, id, onToggle }) => {
  const shoppingLists = useReactiveVar(shoppingListsReactiveVar);
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const [ready, { on }] = useBoolean();
  const { toggle, toggling } = useUpdateShoppingList();
  const { create } = useShoppingList();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isFavorite = useMemo(
    () => shoppingLists?.isFavorite(sku),
    [shoppingLists, sku],
  );
  useEffect(() => {
    on();
  }, []);
  return (
    <Box>
      <Popover closeOnBlur placement="left-start" isOpen={isOpen}>
        <PopoverTrigger>
          <Box />
        </PopoverTrigger>
        <PopoverContent>
          {sessionUser?.data && (
            <SimpleCreateForm
              onSubmit={async (input) => {
                const { data } = await create(input);
                if (!data?.createShoppingList?.shoppingList.id) {
                  return console.error("List id is required");
                }

                await toggle({
                  productId: id,
                  shoppingListId: data?.createShoppingList?.shoppingList.id,
                });
                onToggle?.(true);
                onClose();
              }}
              user={sessionUser.data}
              onCancel={onClose}
            />
          )}
        </PopoverContent>
      </Popover>
      <Menu
        onClose={onClose}
        closeOnBlur={!isOpen}
        closeOnSelect={false}
        placement="top-end"
      >
        <MenuButton
          type="button"
          size="sm"
          aria-label={`Add to list`}
          as={Button}
          isDisabled={!ready}
          {...{
            leftIcon: (
              <Icon
                color={isFavorite ? "blue.800" : "black"}
                as={isFavorite ? BiSolidBookmark : BiBookmark}
              />
            ),
          }}
        >
          Add to List
        </MenuButton>
        <MenuList role="menu" aria-label={"Lists options"}>
          {shoppingLists?.lists?.map((list) => (
            <MenuItem
              isDisabled={toggling}
              key={list.id}
              aria-label={`Add to ${list.name}`}
              aria-checked={shoppingLists?.inList(id, list.id)}
              onClick={async () => {
                await toggle({ productId: id, shoppingListId: list.id });
                onToggle?.(shoppingLists?.inList(id, list.id));
              }}
            >
              <Flex
                w="100%"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Flex gap={2} alignItems="center">
                  <Box
                    style={{ backgroundColor: list.color }}
                    className="w-2 h-2 rounded-sm"
                  />
                  <Text textAlign="start">{list.name}</Text>
                </Flex>
                {shoppingLists?.inList(id, list.id) ? (
                  <Icon as={BiSolidBookmark} color="blue.800" />
                ) : (
                  <BiBookmark />
                )}
              </Flex>
            </MenuItem>
          ))}
          <MenuItem aria-label={"Create new list"} onClick={onOpen}>
            <Flex gap={2}>
              <Text className="w-2 h-2 rounded-sm">+</Text>
              <Text textAlign="start">Create new list</Text>
            </Flex>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default ListMenu;
