import { FC } from "react";
import Image from "next/image";
import { rgbDataURL } from "@/utils/image";
import { Product } from "@/hooks/products/utils";

const ProductImage: FC<Product> = ({ image, catalogName }) => {
  return (
    <Image
      src={image.url}
      placeholder="blur"
      blurDataURL={rgbDataURL(255, 255, 255)}
      priority
      alt={catalogName}
      quality={50}
      fill
      className="object-contain object-top"
      sizes="auto"
    />
  );
};

export default ProductImage;
