"use client";
import { FC, useCallback, useContext } from "react";
import Link from "next/link";
import slug from "slug";
import { Card, CardProps } from "@chakra-ui/react";
import clsx from "clsx";
import { DeepPartial } from "@apollo/client/utilities";
import {
  PackageTypeEnum,
  Product,
} from "@/documents/__generated__/globalTypes.codegen";
import { useShoppingSessionProduct } from "@/hooks/session/useShoppingSessionProduct";
import { useProductEvent } from "@/hooks/analytics/trackers/useProductEvent";
import { EventAction } from "@/app/lib/analytics/types";
import { CellEventMetadata } from "@/hooks/analytics/types";
import { StoreContext } from "@/app/StoreProvider";
import Body from "@/components/ProductList/ProductCard/Body";

export type DisplayOptions = {
  add?: boolean;
  specs?: boolean;
  price?: boolean;
  brand?: boolean;
  volumeDiscount?: boolean;
  pricePerUOM?: boolean;
  delivery?: boolean;
  subTotal?: boolean;
  packagingType?: boolean;
  jit?: boolean;
  favorite?: boolean;
  quantityDescription?: boolean;
  meta?: boolean;
  order?: boolean;
};

type Display = "inline" | "list" | "default";

export type ProductCardProps = CardProps & {
  cellMetadata: CellEventMetadata;
  displayOptions?: DisplayOptions;
  display?: Display;
  animate?: boolean;
  product: DeepPartial<Product>;
  onClick?: (product: DeepPartial<Product>) => void;
  subTotal?: number;
  discountApplied?: boolean;
  allowNavigation?: boolean;
};

const DISPLAY_OPTIONS_DEFAULTS: DisplayOptions = {
  price: true,
  specs: true,
  pricePerUOM: true,
  brand: true,
  volumeDiscount: true,
  delivery: false,
  subTotal: false,
  packagingType: false,
  jit: true,
  favorite: true,
  add: true,
  quantityDescription: true,
  meta: true,
  order: false,
} as const;

const PRODUCT_CARD_PROPS_DEFAULTS: Partial<ProductCardProps> = {
  size: "sm",
  as: Link,
  display: "default",
  variant: "brand",
  allowNavigation: true,
};

export const getDisplayOptions = (displayOptions?: Partial<DisplayOptions>) => {
  return {
    ...DISPLAY_OPTIONS_DEFAULTS,
    ...displayOptions,
  };
};

export const getPropsWithDefaults = (props: ProductCardProps) => {
  return {
    ...PRODUCT_CARD_PROPS_DEFAULTS,
    ...props,
  };
};

const ProductCard: FC<ProductCardProps> = (props) => {
  const propsWithDefaults = getPropsWithDefaults(props);
  const {
    displayOptions,
    product,
    cellMetadata,
    onClick,
    display,
    variant,
    size,
    as,
    allowNavigation,
    subTotal,
    discountApplied,
    animate,
    ...rest
  } = propsWithDefaults;
  const options = getDisplayOptions(displayOptions);
  const store = useContext(StoreContext);
  const showLowestVariantPrice =
    store?.vendor?.settings?.showLowestVariantPrice;
  const defaultPackageType =
    showLowestVariantPrice != null
      ? showLowestVariantPrice
        ? PackageTypeEnum.Unit
        : PackageTypeEnum.Case
      : PackageTypeEnum.Unit;
  const { product: baseProduct } = useShoppingSessionProduct(
    product,
    defaultPackageType,
  );
  const { trackProductCell } = useProductEvent(baseProduct);
  const handleClick = useCallback(
    (product: DeepPartial<Product>) => {
      trackProductCell(EventAction.TAP_QUICK_ADD, cellMetadata);
      return onClick?.(product);
    },
    [cellMetadata, onClick, trackProductCell],
  );
  const { sku, catalogName } = baseProduct;
  return (
    <Card
      className={clsx("group", {
        inherit: display === "list",
      })}
      variant={variant}
      size={size}
      as={as}
      {...(as === Link && {
        color: "blue.400",
        _hover: {
          color: "blue.600",
          borderColor: "gray.200",
        },
        prefetch: false,
        href: `/sku/${slug(catalogName)}/${sku}`,
        onClick: (e) => {
          if (!allowNavigation) {
            e.preventDefault();
            return;
          }
          trackProductCell(EventAction.TAP, cellMetadata);
        },
        role: "link",
      })}
      fontWeight="semibold"
      {...rest}
    >
      <Body
        {...propsWithDefaults}
        subTotal={subTotal}
        discountApplied={discountApplied}
        animate={animate}
        options={options}
        onClick={handleClick}
        baseProduct={baseProduct}
      />
    </Card>
  );
};

export default ProductCard;
