import { gql } from "@apollo/client";

export const MUTATION_CREATE_SHOPPING_LIST_ITEM = gql`
  mutation CreateShoppingListItem($input: CreateShoppingListItemInput!) {
    createShoppingListItem(input: $input) {
      shoppingList {
        id
      }
    }
  }
`;
