import { DeepPartial } from "@apollo/client/utilities";
import {
  DeliveryTime,
  DeliveryTimeConnection,
  Image,
  Maybe,
  Order,
  OrderItem as OrderItemType,
  PackageTypeEnum,
  Product,
  ShoppingSession,
  ShoppingSessionDelivery,
} from "@/documents/__generated__/globalTypes.codegen";
import isDefined from "@/utils/isDefined";
import {
  getPackageType,
  getPackageTypeTitle,
  getVolumeDiscountedPriceLevel,
  parseProduct,
} from "@/hooks/products/utils";
import { ApolloError } from "@apollo/client";

type Key = `${string}|${PackageTypeEnum}`;

const serializeKey = ([sku, packagingType]: [string, PackageTypeEnum]): Key => {
  return `${sku}|${packagingType}`;
};

export type OrderItemVariant = DeepPartial<{
  packageTypeTitle: {
    short: string;
    long: string;
  };
  quantity: number;
  subTotal: number;
  id: number;
  product: DeepPartial<Product>;
  packagingType: PackageTypeEnum;
  discountApplied: boolean;
}>;

const orderItems = (deliveries?: DeepPartial<ShoppingSessionDelivery[]>) => {
  const orderItemsByVariantMap = new Map<Key, OrderItemVariant>();

  const orderItemsByIDMap = new Map<number, OrderItemVariant>();

  deliveries?.filter(isDefined).forEach((delivery) => {
    delivery?.orders?.nodes?.filter(isDefined).forEach((order) => {
      order.orderItems?.nodes?.filter(isDefined).forEach((orderItem) => {
        if (
          orderItem.product?.sku &&
          orderItem.quantity &&
          orderItem.id &&
          orderItem.packagingType
        ) {
          const packagingType = getPackageType(orderItem.packagingType);
          orderItemsByVariantMap.set(
            serializeKey([orderItem.product.sku, packagingType]),
            {
              quantity: orderItem.quantity,
              product: orderItem.product,
              subTotal: Number(orderItem.subTotal),
              packagingType,
              id: orderItem.id,
              packageTypeTitle: {
                short: getPackageTypeTitle({
                  packagingType,
                  short: true,
                }),
                long: getPackageTypeTitle({
                  packagingType,
                  short: false,
                }),
              },
            },
          );
          orderItemsByIDMap.set(orderItem.id, {
            ...orderItem,
            packagingType,
          });
        }
      });
    });
  });

  return {
    orderItemsByVariantMap,
    orderItemsByIDMap,
  };
};

export type OrderItem = DeepPartial<Omit<OrderItemType, "packagingType">> & {
  id: number;
  product: DeepPartial<Product>;
  packagingType: PackageTypeEnum;
  discountApplied: boolean;
  packageTypeTitle: {
    short: string;
    long: string;
  };
};

export type Delivery = DeepPartial<ShoppingSessionDelivery> & {
  gallery: Image[];
  productsCount: number;
  subTotal: number;
  closestAvailableDate: number;
  closestAvailableTime?: DeepPartial<DeliveryTime>;
  orders: (DeepPartial<Omit<Order, "orderItems" | "productsCount">> & {
    orderItems: OrderItem[];
    closestAvailableDate?: number;
  })[];
  isAvailable: boolean;
};

const parseOrderItem = (orderItem: DeepPartial<OrderItemType>): OrderItem => {
  if (!orderItem.product) {
    throw new Error("Product is required");
  }
  if (!orderItem.id) {
    throw new Error("Order item id is required");
  }
  if (!orderItem.packagingType) {
    throw new Error("Order item packaging type is required");
  }

  const packagingType = getPackageType(orderItem.packagingType);
  const variant = orderItem.product.variants
    ?.filter(isDefined)
    .find(
      ({ packageType }) =>
        packageType && packagingType === getPackageType(packageType),
    );
  const threshold =
    variant?.currentVariantPricing?.priceLevels &&
    getVolumeDiscountedPriceLevel(
      variant.currentVariantPricing.priceLevels.filter(isDefined),
    )?.quantity;
  const discountApplied =
    threshold && orderItem.quantity ? orderItem.quantity >= threshold : false;
  return {
    ...orderItem,
    id: orderItem.id,
    product: orderItem.product,
    packagingType,
    discountApplied,
    packageTypeTitle: {
      short: getPackageTypeTitle({
        packagingType,
        short: true,
      }),
      long: getPackageTypeTitle({
        packagingType,
        short: false,
      }),
    },
  };
};

export const parseOrder = (
  order: DeepPartial<Order>,
  delivery?: DeepPartial<{
    id?: string | number;
    deliveryDate?: Maybe<number>;
    availableDeliveryTimes?: Maybe<DeliveryTimeConnection>;
  }>,
) => {
  const deliveryDate = delivery?.deliveryDate;
  const availableDates =
    delivery?.availableDeliveryTimes?.nodes
      ?.filter(isDefined)
      .map(({ date }) => date)
      .filter(isDefined) || [];
  const items = order.orderItems?.nodes?.filter(isDefined) || [];
  const allJIT = items.every((orderItem) => orderItem.product?.jit);
  const closestAvailableDate = allJIT
    ? Math.max(
        ...items
          .map((orderItem) => orderItem?.product?.customerExpectedDate)
          .filter(isDefined),
      )
    : deliveryDate
      ? deliveryDate
      : availableDates.length
        ? Math.min(...availableDates)
        : undefined;
  return {
    ...order,
    orderItems:
      order.orderItems?.nodes?.filter(isDefined).map(parseOrderItem) || [],
    closestAvailableDate,
  };
};

const parseDelivery = (
  delivery?: DeepPartial<ShoppingSessionDelivery>,
): Delivery => {
  const closestAvailableTime = delivery?.availableDeliveryTimes?.nodes
    ?.filter(isDefined)
    .sort((a, b) => {
      if (!a.date || !b.date || !a.start || !b.start) {
        return 0;
      }
      return a.date + a.start - (b.date + b.start);
    })[0];
  const closestAvailableDate = Math.min(
    ...(delivery?.availableDeliveryTimes?.nodes
      ?.map((node) => node?.date)
      .filter(isDefined) || []),
  );
  const nodes: DeepPartial<Order>[] =
    delivery?.orders?.nodes?.filter(isDefined) || [];
  const gallery = nodes
    .map((order) => order.orderItems?.nodes)
    .flat()
    .filter(isDefined)
    .map(
      (orderItem) =>
        orderItem?.product && parseProduct(orderItem?.product).image,
    )
    .flat()
    .filter(isDefined);
  const orders = nodes.map((order) => parseOrder(order, delivery));
  return {
    ...delivery,
    gallery,
    fees: delivery?.fees || [],
    productsCount: delivery?.productsCount || 0,
    subTotal: delivery?.subTotal || 0,
    orders,
    closestAvailableDate,
    closestAvailableTime,
    isAvailable: (delivery?.availableDeliveryTimes?.nodes?.length ?? 0) > 0,
  };
};

export const parseDeliveries = (
  deliveries?: DeepPartial<ShoppingSessionDelivery[]>,
) => {
  return (deliveries || []).map(parseDelivery);
};

export const parseCart = (session?: DeepPartial<ShoppingSession>) => {
  const { orderItemsByIDMap, orderItemsByVariantMap } = orderItems(
    session?.deliveries,
  );
  const deliveries = session?.deliveries?.filter(isDefined);

  return {
    id: session?.id,
    quickCheckoutAvailable: session?.quickCheckoutAvailable,
    promoCodeDiscountCash: session?.promoCodeDiscountCash,
    total: session?.total,
    cartTotal: session?.cartTotal,
    subTotal: session?.subTotal,
    creditCardCharge: session?.creditCardCharge,
    productCount: deliveries
      ?.map((delivery) => delivery.productsCount)
      .filter(isDefined)
      .reduce((acc, curr) => acc + curr, 0),
    deliveries: parseDeliveries(deliveries),
    getOrderItem: (id: number) => {
      return orderItemsByIDMap.get(id);
    },
    getVariantOrderItem: (sku: string, packagingType: PackageTypeEnum) => {
      return orderItemsByVariantMap.get(
        serializeKey([sku, getPackageType(packagingType)]),
      );
    },
    updatedInformation: session?.updatedItemsInformation,
    getATPItemByProductID: (id?: number) => {
      if (session?.updatedItemsInformation) {
        return session.updatedItemsInformation
          .filter(isDefined)
          .find(({ product }) => product?.id === id);
      }
    },
  };
};

const OUT_OF_STOCK_ERROR = "order_out_of_stock";

export const findAtpRejectionError = (error: ApolloError) =>
  error.graphQLErrors.find(
    (error) => error.extensions?.code === OUT_OF_STOCK_ERROR,
  );
