import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardProps,
  Flex,
  Heading,
  Skeleton,
  SkeletonText,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import clsx from "clsx";

type Size = "sm" | "md";

interface BaseSkeletonProps {
  size: Size;
}

const ProductHeader: FC<{ includeName: boolean }> = ({ includeName }) => {
  if (!includeName) return null;

  return (
    <Box>
      <VStack align="start">
        <Heading size="md" as="h1">
          <Skeleton as="span">Product name</Skeleton>
        </Heading>
        <Text fontSize="md">
          <Skeleton as="span">Product brand</Skeleton>
        </Text>
      </VStack>
    </Box>
  );
};

const CategoryBreadcrumbs: FC = () => (
  <Breadcrumb>
    <BreadcrumbItem>
      <Skeleton>Category</Skeleton>
    </BreadcrumbItem>
    <BreadcrumbItem>
      <Skeleton>Sub category</Skeleton>
    </BreadcrumbItem>
  </Breadcrumb>
);

const ProductGallery: FC<BaseSkeletonProps> = ({ size }) => (
  <VStack spacing={6} align="stretch">
    <Tabs variant="unstyled">
      <TabPanels>
        <TabPanel>
          <Skeleton>
            <Box
              className={clsx("relative", {
                "h-64": size === "sm",
                "h-80": size === "md",
              })}
            />
          </Skeleton>
        </TabPanel>
      </TabPanels>
      <TabList gap={2} className="mt-2">
        {Array.from({ length: 4 }).map((_, index) => (
          <Tab key={index} className="border">
            <Skeleton>
              <Box className="relative w-10 h-10" />
            </Skeleton>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  </VStack>
);

const ProductImage: FC<BaseSkeletonProps> = ({ size }) => (
  <Box className="row-span-2">
    <Skeleton>
      <Card variant="outline" h={size === "md" ? 96 : 64} />
    </Skeleton>
  </Box>
);

const ProductSpecs: FC = () => (
  <TableContainer whiteSpace="wrap">
    <Table size="sm" variant="simple">
      <TableCaption>
        <SkeletonText noOfLines={1} />
      </TableCaption>
      <Thead>
        <Tr>
          <Th colSpan={3}>
            <SkeletonText noOfLines={1} />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {Array.from({ length: 4 }).map((_, index) => (
          <Tr key={index}>
            <Td>
              <SkeletonText noOfLines={1} />
            </Td>
            <Td>
              <SkeletonText noOfLines={1} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
);

interface ProductDetailsSkeletonProps extends Partial<BaseSkeletonProps> {
  variant?: CardProps["variant"];
  includeRecommendations?: boolean;
  includeDescription?: boolean;
  includeName?: boolean;
}

const ProductDetailsSkeleton: FC<ProductDetailsSkeletonProps> = ({
  size = "md",
  includeDescription = true,
  includeName = true,
}) => {
  return (
    <Box
      className={clsx(
        "grid gap-4",
        "grid-rows-[auto_minmax(0,1fr)_auto]",
        size === "sm" ? "grid-cols-2" : "grid-cols-[60%_40%]",
      )}
    >
      <VStack align="stretch" spacing={2}>
        <ProductHeader includeName={includeName} />
        <CategoryBreadcrumbs />
      </VStack>

      <ProductImage size={size} />
      <ProductGallery size={size} />

      {includeDescription && (
        <Box>
          <Flex direction="column" gap={6}>
            <ProductSpecs />
            <Box>
              <Skeleton fitContent>
                <Button size="sm">Proposition 65 warning</Button>
              </Skeleton>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default ProductDetailsSkeleton;
