import clsx from "clsx";
import { Box, Flex, Grid, Icon } from "@chakra-ui/react";
import { BiSolidBookmarkHeart } from "react-icons/bi";
import ProductCart from "@/components/ProductList/ProductCard/Cart";
import ProductImage from "@/components/ProductList/ProductCard/Image";
import ProductName from "@/components/ProductList/ProductCard/Name";
import ProductMeta from "@/components/ProductList/ProductCard/Meta";
import { FC } from "react";
import { BodyProps } from "@/components/ProductList/ProductCard/Body";

const GridView: FC<BodyProps> = ({
  options,
  baseProduct,
  product,
  animate,
  discountApplied,
  subTotal,
  display,
  onClick,
  size,
  children,
}) => {
  const { isFavourite, orderItems } = baseProduct;
  return (
    <Grid
      {...(display === "inline"
        ? {
            gridTemplateColumns: clsx(
              {
                ["4rem"]: size === "xs",
                ["8rem"]: size === "sm",
              },
              "1fr",
            ),
          }
        : {
            gridTemplateRows: clsx(
              {
                ["4rem"]: size === "xs",
                ["8rem"]: size === "sm",
              },
              "1fr",
            ),
          })}
      gap={4}
      className="relative"
    >
      {options.favorite && isFavourite && (
        <Icon
          as={BiSolidBookmarkHeart}
          color="pink.500"
          boxSize={8}
          className="absolute z-10"
        />
      )}
      {options.add && (
        <Box className="absolute right-0 z-10">
          <ProductCart
            id={baseProduct.id}
            product={product}
            orderItems={orderItems}
            onClick={onClick}
          >
            {children}
          </ProductCart>
        </Box>
      )}
      <Box
        className={clsx(
          "relative",
          display === "inline" ? "w-full" : "h-full",
          {
            ["group-hover:scale-110 transition-transform origin-center"]:
              animate,
          },
        )}
      >
        <ProductImage {...baseProduct} />
      </Box>
      <Flex direction="column" gap={0.5}>
        <ProductName
          {...baseProduct}
          options={options}
          orderItems={orderItems}
        />
        {options?.meta && (
          <ProductMeta
            {...baseProduct}
            options={options}
            discountApplied={discountApplied}
            subTotal={subTotal}
          />
        )}
      </Flex>
    </Grid>
  );
};

export default GridView;
