import { Alert, AlertIcon, Box, Text, VStack } from "@chakra-ui/react";

const Incomplete = () => {
  return (
    <VStack spacing={4}>
      <Alert status="info">
        <AlertIcon />
        No restaurants found
      </Alert>
      <Box>
        <Text>
          Seems like you don&#39;t have any restaurants assigned to your
          account.
        </Text>
        <Text>
          Please complete the registration process or continue as a guest. You
          can always complete the registration process later.
        </Text>
      </Box>
    </VStack>
  );
};

export default Incomplete;
