import {
  Category,
  Dimensions,
  Image,
  Maybe,
  PackageTypeEnum,
  PriceLevel,
  Product as ProductType,
  Spec,
  Variant,
  VariantPricing,
} from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";
import isDefined from "@/utils/isDefined";
import sortBy from "lodash/sortBy";
import isPresent from "@/utils/isPresent";
import { OrderItemVariant, parseCart } from "@/hooks/cart/utils";
import { parseShoppingLists } from "@/hooks/lists/utils";
import uniqBy from "lodash/uniqBy";

const PRODUCT_PLACEHOLDER_IMAGE: Image = {
  __typename: "Image",
  id: 0,
  priority: 0,
  type: "",
  url: "/img/product_placeholder.png",
  hqUrl: "/img/product_placeholder.png",
};

export const getImage = (variants?: DeepPartial<Variant>[]): Image => {
  const [image] = sortBy(
    variants
      ?.filter(isDefined)
      .flatMap((variant) => variant.images)
      .filter(isDefined),
    "priority",
  );
  return (image || PRODUCT_PLACEHOLDER_IMAGE) as Image;
};

export const getCategory = (categories?: DeepPartial<Category>[]) => {
  if (!categories) {
    return null;
  }
  const [category] = categories;
  return category;
};

export const getBasePrice = (variants: DeepPartial<Variant>[]): number => {
  const [variant] = variants;
  return variant?.price || 0;
};

export const getPackageType = (packageType?: string): PackageTypeEnum => {
  if (packageType?.toLowerCase() === PackageTypeEnum.Case.toLowerCase()) {
    return PackageTypeEnum.Case;
  }

  if (packageType?.toLowerCase() === PackageTypeEnum.Unit.toLowerCase()) {
    return PackageTypeEnum.Unit;
  }

  return PackageTypeEnum.Case;
};

export const getPackagingTypes = (
  variants: DeepPartial<Variant>[],
): PackageTypeEnum[] => {
  return variants.flatMap((variant) => getPackageType(variant.packageType));
};

export const getPackageTypeTitle = ({
  packagingType,
  short,
}: {
  packagingType: PackageTypeEnum;
  short?: boolean;
}): string => {
  if (packagingType === PackageTypeEnum.Unit) {
    return short ? "EA" : "Each";
  } else {
    return short ? "CS" : "Case";
  }
};

export type Product = {
  id: number;
  sku: string;
  catalogName: string;
  fullName?: string;
  brand?: Maybe<string>;
  description?: Maybe<string>;
  discontinued?: boolean;
  image: Image;
  upcs?: (string | undefined)[];
  category: Maybe<DeepPartial<Category>>;
  price: number;
  itemTypes?: PackageTypeEnum[];
  variants: EnhancedVariant[];
  catchweightItem?: boolean;
  specs: DeepPartial<Spec>[];
  inStock?: boolean;
  atp?: boolean;
  jit?: boolean;
  customerExpectedDate?: Maybe<number>;
  nextReceiveDate?: Maybe<number>;
  mainCategory?: Maybe<DeepPartial<Category>>;
  subCategory?: Maybe<DeepPartial<Category>>;
  gallery?: DeepPartial<Image>[];
  getVariantByPackageType: (
    packageType: PackageTypeEnum,
  ) => EnhancedVariant | undefined;

  baseVariant?: ReturnType<typeof getProductBaseVariant> & {
    orderItemPricing?: {
      price: number;
      unit: PackageTypeEnum | string;
    };
  };
  replacements?: Product[];
  replaceable?: boolean;
  orderItems?: OrderItemVariant[];
  isFavourite?: boolean;
  inCart?: boolean;
  baseOrderItem?: OrderItemVariant;
};

export type EnhancedVariant = DeepPartial<Variant> & {
  packageType: PackageTypeEnum;
  packageTypeTitle: string;
  quantity?: number;
  volumeDiscountedPriceLevel?: DeepPartial<PriceLevel>;
  currentVariantPricing?: DeepPartial<VariantPricing>;
  orderItemId?: number;
  baseVariantPrice?: ReturnType<typeof getBaseVariantPrice>;
  dimensions?: DeepPartial<Dimensions>;
};

const getVariants = (
  sku: string,
  variants?: DeepPartial<Variant>[],
  cart?: ReturnType<typeof parseCart> | null,
): EnhancedVariant[] => {
  return (variants || [])
    .map(({ dimensions, ...variant }): EnhancedVariant => {
      const packageType = getPackageType(variant.packageType);
      const orderItem = cart?.getVariantOrderItem(sku, packageType);
      const images = variant.images?.filter((image) => image?.url !== null);
      const volumeDiscountedPriceLevel =
        getVariantVolumeDiscountedPriceLevel(variant);
      const hasDimensions = Boolean(
        dimensions?.width != null ||
          dimensions?.height != null ||
          dimensions?.length != null,
      );
      return {
        ...variant,
        volumeDiscountedPriceLevel,
        packageType,
        currentVariantPricing: variant.currentVariantPricing,
        baseVariantPrice: getBaseVariantPrice(variant),
        packageTypeTitle: getPackageTypeTitle({
          packagingType: packageType,
        }),
        images: images?.length ? images : [PRODUCT_PLACEHOLDER_IMAGE],
        ...(orderItem && {
          orderItemId: orderItem.id,
          quantity: orderItem.quantity,
        }),
        ...(hasDimensions && {
          dimensions,
        }),
      };
    })
    .sort(({ packageType }) => (packageType === PackageTypeEnum.Case ? -1 : 1));
};

const getMinimalPrice = (...levels: DeepPartial<PriceLevel>[]) => {
  return Math.min(
    ...levels
      .map((level) => {
        return [level?.price, level?.discountedPrice].filter(isPresent);
      })
      .flat(),
  );
};

const getBaseVariantPrice = (variant: DeepPartial<Variant>) => {
  const priceLevels =
    variant?.currentVariantPricing?.priceLevels?.filter(isDefined);
  if (!priceLevels?.length) {
    return null;
  }
  const [basePriceLevel] = priceLevels;
  const price = getMinimalPrice(basePriceLevel);
  const basePrice = basePriceLevel.price;
  const discounted = Boolean(basePrice && price < basePrice);
  return {
    price,
    basePrice,
    discounted,
    quantity: basePriceLevel.quantity,
    catchweightPrice: basePriceLevel.catchweightPrice,
  };
};

const getBaseVariantPriceLevelContext = (variant: DeepPartial<Variant>) => {
  const priceLevels =
    variant?.currentVariantPricing?.priceLevels?.filter(isDefined);
  if (!priceLevels?.length) {
    return;
  }
  const [priceLevel] = priceLevels.filter((level) => isDefined(level?.context));

  return priceLevel?.context;
};

export const getVolumeDiscountedPriceLevel = (
  priceLevels: DeepPartial<PriceLevel>[],
): DeepPartial<PriceLevel> => {
  const [priceLevel] = priceLevels
    .filter(({ quantity }) => !!quantity)
    .sort(({ quantity: q1 = 0 }, { quantity: q2 = 0 }) => q1 - q2);

  return priceLevel;
};

const getVariantVolumeDiscountedPriceLevel = (
  variant?: DeepPartial<Variant>,
) => {
  const priceLevels =
    variant?.currentVariantPricing?.priceLevels?.filter(isDefined);
  if (!priceLevels?.length) {
    return;
  }
  return getVolumeDiscountedPriceLevel(priceLevels);
};

const getProductBaseVariant = (
  variants?: DeepPartial<Variant>[],
  packageType: PackageTypeEnum = PackageTypeEnum.Unit,
) => {
  const variant =
    variants?.find(
      (variant) => getPackageType(variant?.packageType) === packageType,
    ) || variants?.[0];
  if (!variant) {
    return;
  }
  const price = getBaseVariantPrice(variant);
  const priceLevelContext = getBaseVariantPriceLevelContext(variant);
  const volumeDiscountedPriceLevel =
    getVariantVolumeDiscountedPriceLevel(variant);

  return {
    variant,
    price,
    packageType: getPackageType(variant.packageType),
    priceLevelContext,
    volumeDiscountedPriceLevel,
    quantityDescription: variant.quantityDescription,
    itemsCount: variant.itemsCount,
    weight: variant.weight,
  };
};

export const parseProduct = (
  node: DeepPartial<ProductType>,
  session?: {
    cart: ReturnType<typeof parseCart> | null;
    lists: ReturnType<typeof parseShoppingLists> | null;
    packageType?: PackageTypeEnum;
  },
): Product => {
  const sku = node.sku;
  if (!node.id) {
    throw new Error("Product id is required");
  }
  if (!sku) {
    throw new Error("Product sku is required");
  }
  const baseVariants = node.variants?.filter(isDefined);
  const categories = node.categories?.nodes?.filter(isDefined);
  const variants = getVariants(sku, baseVariants, session?.cart);
  const baseImage = getImage(baseVariants);
  const gallery = uniqBy(
    node.variants
      ?.map((variant) => {
        if (variant?.images?.length) {
          return variant.images
            .filter(isDefined)
            .sort(({ priority: p1 = 0 }, { priority: p2 = 0 }) => p1 - p2);
        }
        return [baseImage];
      })
      .filter(isDefined)
      .flat(),
    "url",
  );
  const getVariantByPackageType = (packageType: PackageTypeEnum) => {
    return variants?.find((variant) => variant.packageType === packageType);
  };
  const name = node.catalogName || node.fullName;
  if (!name) {
    throw new Error("Product name is required");
  }
  const replacements = node.replacements
    ?.filter(isDefined)
    .map((replacement) => parseProduct(replacement));

  const itemTypes = getPackagingTypes(variants);
  const orderItems = itemTypes
    .map((packagingType) => {
      return session?.cart?.getVariantOrderItem(sku, packagingType);
    })
    .filter(isPresent);
  const baseVariant = getProductBaseVariant(variants, session?.packageType);
  const baseOrderItem = orderItems?.find((item) => {
    return item?.packagingType === baseVariant?.packageType;
  });

  return {
    id: node.id,
    sku,
    catalogName: name,
    nextReceiveDate: node.nextReceiveDate,
    brand: node.brand,
    fullName: node.fullName,
    discontinued: node.discontinued,
    image: baseImage,
    variants,
    price: getBasePrice(variants),
    itemTypes: getPackagingTypes(variants),
    specs: node.specs?.filter(isDefined) || [],
    getVariantByPackageType,
    description: node.description,
    category: getCategory(categories),
    inStock: node.inStock,
    atp: node.atp,
    jit: node.jit,
    catchweightItem: node.catchweightItem,
    // TODO: Understand better what are possible values for specType
    customerExpectedDate: node.customerExpectedDate,
    baseVariant,
    mainCategory: node.mainCategory,
    subCategory: node.subCategory,
    gallery,
    upcs: node.upcs,
    replacements,
    ...(replacements && {
      replaceable: replacements.length > 0,
    }),
    orderItems,
    isFavourite: session?.lists?.isFavorite(sku),
    ...(baseOrderItem?.product && {
      orderProduct: parseProduct(baseOrderItem.product),
    }),
    inCart: !!orderItems?.length,
  };
};
