"use client";
import clsx from "clsx";
import {
  Box,
  Button,
  CardProps,
  Divider,
  Flex,
  Heading,
  Icon,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsBellFill, BsBellSlashFill, BsBoxSeam } from "react-icons/bs";
import ProductVariants from "@/components/ProductList/ProductDetails/Variants";
import { getDollarsDisplayPrice } from "@/utils/price";
import { humanizeDate } from "@/utils/date";
import ProductLists from "@/components/ProductList/ProductDetails/Lists";
import ProductList from "@/components/ProductList";
import { FC, useEffect, useMemo } from "react";
import { Product } from "@/hooks/products/utils";
import { useFormContext } from "react-hook-form";
import { Inputs } from "@/components/ProductList/ProductDetails";
import { EventSource } from "@/app/lib/analytics/types";
import { useReminder } from "@/hooks/reminder/useReminder";
import {
  VariantOption,
  Option,
} from "@/components/ProductList/ProductDetails/QuantitySelect";
import SignInGate from "@/components/SignInGate";
import { useBoolean } from "@chakra-ui/icons";
import { BiErrorCircle } from "react-icons/bi";

type ProductMainProps = {
  product: Product;
  remind: boolean;
  loading: boolean;
  defaultDisabled: boolean;
  total: number;
  variant: CardProps["variant"];
  onToggleReminder: (isSubscribed: boolean) => void;
  onFocus: (option: VariantOption & { options: Option[] }) => void;
  onBlur: (option: VariantOption & { options: Option[] }) => void;
  onToggleList: (inList: boolean) => void;
};

const Discontinued: FC = () => {
  return (
    <Flex
      gap={4}
      alignItems="center"
      color="gray.400"
      className="cursor-not-allowed"
    >
      <Icon as={BiErrorCircle} boxSize={10} />
      <Heading size="md" as="h4">
        Item discontinued
      </Heading>
    </Flex>
  );
};

type OutOfStockProps = {
  id: number;
  remind: boolean;
  onToggle?: (isSubscribed: boolean) => void;
};

const OutOfStock: FC<OutOfStockProps> = ({ id, onToggle, remind }) => {
  const { isSubscribed, toggleSubscription, isLoading } = useReminder(id);

  return (
    <VStack align="stretch" spacing={8} pt={6}>
      <Flex gap={4} alignItems="center" justifyContent="center">
        <Icon as={BsBoxSeam} boxSize={10} />
        <Heading size="md" as="h4">
          Back in stock soon
        </Heading>
      </Flex>
      <SignInGate>
        <Button
          w="100%"
          leftIcon={
            <Icon
              as={isSubscribed ? BsBellSlashFill : BsBellFill}
              boxSize={3}
            />
          }
          isLoading={remind && isLoading}
          size="sm"
          colorScheme={isSubscribed ? "midnight_navy" : "blue"}
          onClick={() => {
            if (remind) {
              toggleSubscription();
              onToggle?.(isSubscribed);
            }
          }}
        >
          {isSubscribed
            ? "Unsubscribe from notification"
            : "Get notified when in stock"}
        </Button>
      </SignInGate>
    </VStack>
  );
};

const ProductMain: FC<ProductMainProps> = ({
  product,
  total,
  loading,
  variant,
  remind,
  onToggleReminder,
  onFocus,
  onBlur,
  onToggleList,
  defaultDisabled,
}) => {
  const {
    watch,
    formState: { isDirty },
  } = useFormContext<Inputs>();
  const available = Boolean(
    product.atp && product.inStock && !product.discontinued,
  );
  const isRemove =
    watch("variants").reduce((acc, variant) => {
      if (variant.quantity == null) {
        return acc;
      }
      return acc + variant.quantity;
    }, 0) === 0;
  const [disabled, { on, off }] = useBoolean();
  useEffect(() => {
    if (defaultDisabled) {
      on();
    } else {
      off();
    }
  }, [defaultDisabled]);
  const isDisabled = useMemo(() => {
    return disabled || Boolean(!isDirty && product.inCart);
  }, [isDirty, disabled, product.inCart]);
  return (
    <Flex
      direction="column"
      gap={4}
      className={clsx("relative", {
        ["shadow-md border rounded-lg p-4 sticky top-[100px]"]:
          variant === "elevated",
      })}
    >
      {disabled && (
        <Box className="absolute top-0 left-0 w-full">
          <Progress isIndeterminate size="xs" />
        </Box>
      )}
      {product.discontinued ? (
        <Discontinued />
      ) : (
        <VStack align="stretch" spacing={4}>
          <ProductVariants
            onBlur={onBlur}
            onFocus={onFocus}
            loading={loading || disabled}
            available={available}
          />
          {available && (
            <Box>
              <SignInGate>
                <Button
                  isLoading={loading}
                  isDisabled={isDisabled}
                  w="100%"
                  colorScheme="midnight_navy"
                  type="submit"
                >
                  {product.inCart
                    ? isRemove
                      ? "Remove"
                      : "Update"
                    : "Add to cart"}{" "}
                  {total ? `${getDollarsDisplayPrice(total)}` : null}
                </Button>
              </SignInGate>
            </Box>
          )}
          {product.customerExpectedDate && !product.jit && available && (
            <Text align="center" fontSize="sm" color="gray.500">
              Order today, receive as early as{" "}
              {humanizeDate(product.customerExpectedDate)}
            </Text>
          )}
          <Divider />
          <ProductLists onToggleList={onToggleList} product={product} />
        </VStack>
      )}
      {!product.discontinued && (!product.atp || !product.inStock) && (
        <>
          <OutOfStock
            remind={remind}
            onToggle={onToggleReminder}
            id={product.id}
          />
        </>
      )}
      {product.replacements?.length ? (
        <Flex direction="column" gap={2}>
          <Divider />
          <ProductList
            size="sm"
            title="Related items"
            variant="carousel"
            products={product.replacements}
            cellMetadata={{
              source_object: EventSource.UNAVAILABLE_PRODUCT,
            }}
          />
        </Flex>
      ) : null}
    </Flex>
  );
};

export default ProductMain;
