import { FC, RefObject, useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  UseDisclosureReturn,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { EmptyShoppingSessionInput } from "@/documents/__generated__/globalTypes.codegen";
import { useForm } from "react-hook-form";

type ClearCartModalProps = UseDisclosureReturn & {
  onSubmit: (values: EmptyShoppingSessionInput) => void;
};

const ClearCartModal: FC<ClearCartModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<EmptyShoppingSessionInput>();
  const cancelRef = useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog
      leastDestructiveRef={cancelRef as RefObject<HTMLButtonElement>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Are you sure?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          Do you want to clear your cart? This action cannot be undone.
        </AlertDialogBody>

        <AlertDialogFooter>
          <ButtonGroup>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              type="button"
              colorScheme="red"
            >
              Yes
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ClearCartModal;
