import { gql } from "@apollo/client";

export const QUERY_MOVE_ITEMS_DELIVERIES = gql`
  query MoveItemsDeliveries($restaurantId: Int!) {
    restaurant(id: $restaurantId) {
      id
      currentShoppingSession {
        id
        moveItemsDeliveries {
          id
          productsCount
          total
          cartTotal
          freeShortage
          orders {
            nodes {
              id
              deliveryTimeStart
              deliveryTimeEnd
              deliveryWindowId
              deliveryDate
            }
          }
          availableDeliveryTimes {
            nodes {
              id
              price
              date
              start
              end
              customerFacingMessage
            }
          }
        }
      }
    }
  }
`;
