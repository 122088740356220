import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";

export const QUERY_SHOPPING_LISTS = gql`
  ${FRAGMENT_PRODUCT}
  query ShoppingLists(
    $restaurantId: Int!
    $ids: [Int!]
    $includeByIDs: Boolean = false
    $includeVariants: Boolean = false
  ) {
    restaurant(id: $restaurantId) {
      id
      byIDs: shoppingLists(ids: $ids) @include(if: $includeByIDs) {
        nodes {
          id
          name
          color
          description
          position
          shoppingListItems {
            nodes {
              id
              position
              lastDeliveryInformation {
                date
              }
              product {
                ...FragmentProduct
              }
            }
          }
          shoppingListItemsForCleanUp {
            nodes {
              id
              position
              lastDeliveryInformation {
                date
              }
              product {
                ...FragmentProduct
              }
            }
          }
        }
      }
      shoppingLists {
        nodes {
          id
          name
          color
          description
          position
          shoppingListItems {
            nodes {
              id
              position
              product {
                id
                sku
                catalogName
                variants @include(if: $includeVariants) {
                  id
                  images {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
