"use client";
import { FC } from "react";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { Product } from "@/hooks/products/utils";
import { useRecommendations } from "@/hooks/products/useRecommendations";
import { RecommendationSourceEnum } from "@/documents/__generated__/globalTypes.codegen";
import ProductList from "@/components/ProductList";
import { lowerCase } from "lodash";
import capitalize from "lodash/capitalize";
import { EventSource } from "@/app/lib/analytics/types";

type ProductRecommendationsProps = {
  product: Product;
  source?: RecommendationSourceEnum;
};

const ProductRecommendations: FC<ProductRecommendationsProps> = ({
  product,
  source = RecommendationSourceEnum.OthersYouMayLike,
}) => {
  const { products, loading } = useRecommendations({
    sku: product.sku,
    source,
  });

  if (!loading && !products?.length) {
    return null;
  }

  return (
    <Box className="col-span-2">
      {loading ? (
        <Flex direction="column" gap={4}>
          <Skeleton fitContent>
            <Text as={"span"}>{capitalize(lowerCase(source))}</Text>
          </Skeleton>
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {Array.from({ length: 6 }).map((_, index) => (
              <GridItem key={index}>
                <Card variant="outline" size="sm">
                  <CardBody>
                    <Flex direction="column" gap={2}>
                      <Skeleton style={{ width: "100%", height: 128 }} />
                      <SkeletonText noOfLines={5} />
                    </Flex>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      ) : (
        <ProductList
          title={capitalize(lowerCase(source))}
          variant="carousel"
          products={products}
          cellMetadata={{
            source_object: EventSource.PRODUCT_OTHER_YOU_MAY_LIKE,
          }}
        />
      )}
    </Box>
  );
};

export default ProductRecommendations;
