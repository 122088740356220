import { DeepPartial } from "@apollo/client/utilities";
import { OrderItemVariant } from "@/hooks/cart/utils";
import { FC, PropsWithChildren } from "react";
import { Button, ButtonGroup, Flex, Icon, IconButton } from "@chakra-ui/react";
import { BiCartAlt } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import SignInGate from "@/components/SignInGate";
import { Product } from "@/documents/__generated__/globalTypes.codegen";

type ProductCartProps = {
  id: number;
  onClick?: (product: DeepPartial<Product>) => void;
  product: DeepPartial<Product>;
  orderItems?: OrderItemVariant[];
};

const ProductCart: FC<PropsWithChildren<ProductCartProps>> = ({
  onClick,
  product,
  orderItems,
  children,
}) => {
  return (
    <Flex justifyContent="end">
      <ButtonGroup
        onClick={(e) => {
          // NOTE: do not bubble up for CTAs
          e.preventDefault();
        }}
      >
        {orderItems?.length ? (
          <Button
            leftIcon={<Icon as={BiCartAlt} boxSize={4} />}
            variant="solid"
            size="sm"
            colorScheme="blue"
            aria-label="Add to cart"
            onClick={(e) => {
              e.preventDefault();
              onClick?.(product);
            }}
          >
            {orderItems
              .map(
                (orderItem) =>
                  `${orderItem.quantity} ${orderItem.packageTypeTitle?.short}`,
              )
              .join(", ")}
          </Button>
        ) : (
          <SignInGate
            as={IconButton}
            variant="solid"
            size="sm"
            colorScheme="light"
            color={"black"}
            className="shadow-elevation-1"
            aria-label="Add to cart"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.(product);
            }}
            icon={<Icon as={BsPlus} boxSize={6} />}
          ></SignInGate>
        )}
        {children}
      </ButtonGroup>
    </Flex>
  );
};

export default ProductCart;
