import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT } from "@/documents/fragments/FRAGMENT_PRODUCT";
import { FRAGMENT_DELIVERY } from "@/documents/fragments/FRAGMENT_DELIVERY";

export const FRAGMENT_SHOPPING_SESSION = gql`
  ${FRAGMENT_PRODUCT}
  ${FRAGMENT_DELIVERY}
  fragment FragmentShoppingSession on ShoppingSession {
    id
    cartTotal
    total
    subTotal
    creditCardCharge
    quickCheckoutAvailable
    promoCodeDiscountCash
    deliveries {
      ...FragmentDelivery
    }

    updatedItemsInformation @include(if: $includeUpdatedItemsInformation) {
      id
      quantity
      originalQuantity
      price
      packagingType
      product {
        ...FragmentProduct
      }
    }
  }
`;
