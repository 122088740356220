import useToastedMutation from "@/hooks/toasted/useToastedMutation";
import { MUTATION_EMPTY_SHOPPING_SESSION } from "@/documents/MUTATION_EMPTY_SHOPPING_SESSION";
import { useCallback } from "react";
import {
  EmptyShoppingSessionMutation,
  EmptyShoppingSessionMutationVariables,
} from "@/documents/__generated__/MUTATION_EMPTY_SHOPPING_SESSION.codegen";
import { EmptyShoppingSessionInput } from "@/documents/__generated__/globalTypes.codegen";

export const useClearCart = () => {
  const [mutate, { loading }] = useToastedMutation<
    EmptyShoppingSessionMutation,
    EmptyShoppingSessionMutationVariables
  >(MUTATION_EMPTY_SHOPPING_SESSION);

  const clearCart = useCallback(
    (input: EmptyShoppingSessionInput) => {
      return mutate({
        variables: {
          input,
          restaurantId: input.restaurantId,
        },
      });
    },
    [mutate],
  );

  return {
    clearCart,
    loading,
  };
};
