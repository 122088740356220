import { gql } from "@apollo/client";

export const MUTATION_DESTROY_SHOPPING_LIST_ITEM = gql`
  mutation DestroyShoppingListItem($input: DestroyShoppingListItemsInput!) {
    destroyShoppingListItems(input: $input) {
      shoppingList {
        id
      }
    }
  }
`;
