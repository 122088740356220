import { FC } from "react";
import clsx from "clsx";
import ListView from "@/components/ProductList/ProductCard/ListView";
import GridView from "@/components/ProductList/ProductCard/GridView";
import { CardBody } from "@chakra-ui/react";
import {
  DisplayOptions,
  ProductCardProps,
} from "@/components/ProductList/ProductCard";
import { Product } from "@/hooks/products/utils";
import { DeepPartial } from "@apollo/client/utilities";
import { Product as ProductType } from "@/documents/__generated__/globalTypes.codegen";

export type BodyProps = ProductCardProps & {
  baseProduct: Product;
  options: DisplayOptions;
  onClick: (product: DeepPartial<ProductType>) => void;
};

const Body: FC<BodyProps> = (props) => {
  return (
    <CardBody
      className={clsx({
        ["inherit"]: props.display === "list",
      })}
    >
      {props.display === "list" ? (
        <ListView {...props} />
      ) : (
        <GridView {...props} />
      )}
    </CardBody>
  );
};

export default Body;
