"use client";
import {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Card,
  CardBody,
  CardHeader,
  useBreakpointValue,
  Box,
  useBoolean,
} from "@chakra-ui/react";
import isDefined from "@/utils/isDefined";
import ProductCard, {
  ProductCardProps,
} from "@/components/ProductList/ProductCard";
import { DeepPartial } from "@apollo/client/utilities";
import { Maybe, Product } from "@/documents/__generated__/globalTypes.codegen";
import Slider, { Settings } from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/next-js";
import slug from "slug";
import { useDisclosure } from "@chakra-ui/hooks";
import Image from "next/image";
import ProductModal from "@/components/ProductList/ProductModal";
import { isFunction } from "lodash";
import { CellEventMetadata } from "@/hooks/analytics/types";

type Variant = "grid" | "carousel" | "list";
type Size = "sm" | "md" | "lg";

type ProductListProps = {
  variant?: Variant;
  size?: Size;
  products?: DeepPartial<Product>[];
  title?: Maybe<string>;
  uniqueId?: string;
  onViewAll?: () => void;
  onClick?: (product: DeepPartial<Product>) => void;
  onAfterChange?: () => void;
  children?:
    | ReactElement<DeepPartial<Product>>
    | ((props: Partial<DeepPartial<Product>>) => ReactNode);
  itemProps?: Partial<ProductCardProps>;
  cellMetadata: Omit<CellEventMetadata, "position">;
  // Homepage component id
  id?: number;
};

const DISPLAY_OPTIONS = {
  brand: true,
  volumeDiscount: true,
  pricePerUOM: true,
};

const GridView: FC<ProductListProps> = ({
  products,
  itemProps,
  onClick,
  cellMetadata,
}) => {
  const columns = useBreakpointValue(
    {
      base: { templateColumns: "repeat(3, 1fr)", gap: 4 },
      lg: { templateColumns: "repeat(5, 1fr)", gap: 4 },
      xl: { templateColumns: "repeat(6, 1fr)", gap: 4 },
    },
    {
      fallback: "xl",
    },
  );
  return (
    <Grid {...columns}>
      {products?.length ? (
        products?.filter(isDefined).map((product, index) => (
          <GridItem key={product.id}>
            <ProductCard
              {...itemProps}
              onClick={onClick}
              product={product}
              cellMetadata={{
                ...cellMetadata,
                position: index,
              }}
              displayOptions={DISPLAY_OPTIONS}
            />
          </GridItem>
        ))
      ) : (
        <GridItem colSpan={6}>
          <Card align="center" bgColor="whiteAlpha" size="lg" variant="filled">
            <CardHeader>
              <Heading as="h4" size="sm">
                No products found
              </Heading>
            </CardHeader>
            <CardBody>
              <Image
                src="/img/empty_cart.png"
                alt="No products found"
                width={279}
                height={279}
              />
            </CardBody>
          </Card>
        </GridItem>
      )}
    </Grid>
  );
};

const CarouselView: FC<ProductListProps> = ({
  products,
  size,
  title,
  itemProps,
  uniqueId,
  cellMetadata,
  onClick,
  onViewAll,
  onAfterChange,
  id,
}) => {
  const ref = useRef<Slider>(null);
  const [allowNavigation, { on, off }] = useBoolean(true);
  const slidesToShow = useBreakpointValue<number>(
    size === "sm"
      ? {
          base: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
        }
      : {
          base: 3,
          lg: 5,
          xl: 6,
        },
    {
      ssr: true,
      fallback: "xl",
    },
  );
  const {
    canSlick,
    ...settings
  }: Settings & {
    canSlick: boolean;
  } = useMemo(() => {
    return {
      slidesToShow,
      slidesToScroll: slidesToShow,
      arrows: false,
      beforeChange() {
        off();
      },
      afterChange() {
        onAfterChange?.();
        on();
      },
      canSlick: Boolean(
        slidesToShow && products?.length && products?.length > slidesToShow,
      ),
    };
  }, [products?.length, slidesToShow]);
  return (
    <Flex direction="column" gap={4}>
      <Flex justify="space-between" align="center">
        <Heading as="h4" size="sm" noOfLines={1}>
          {title}
        </Heading>
        <ButtonGroup spacing={2} alignItems="center">
          <IconButton
            isDisabled={!canSlick}
            variant="ghost"
            icon={<ChevronLeftIcon boxSize={6} />}
            aria-label="Previous"
            onClick={() => {
              ref.current?.slickPrev();
            }}
          />
          <IconButton
            isDisabled={!canSlick}
            variant="ghost"
            icon={<ChevronRightIcon boxSize={6} />}
            aria-label="Next"
            onClick={() => {
              ref.current?.slickNext();
            }}
          />
          {title && uniqueId && id && (
            <Button
              size="sm"
              as={Link}
              prefetch={false}
              href={`/department/${slug(title)}/${encodeURIComponent(uniqueId)}`}
              onClick={onViewAll}
            >
              View All
            </Button>
          )}
        </ButtonGroup>
      </Flex>
      <Box className="-mx-2">
        {canSlick ? (
          <Slider ref={ref} {...settings}>
            {products?.filter(isDefined).map((product, index) => (
              <Box key={product.id}>
                <Box className="p-2">
                  <ProductCard
                    cellMetadata={{
                      ...cellMetadata,
                      position: index,
                    }}
                    allowNavigation={allowNavigation}
                    onClick={onClick}
                    product={product}
                    displayOptions={DISPLAY_OPTIONS}
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        ) : (
          <Grid
            px={2}
            templateColumns={`repeat(${slidesToShow}, minmax(0, 1fr))`}
            gap={4}
          >
            {products?.filter(isDefined).map((product, index) => (
              <GridItem key={product.id}>
                <ProductCard
                  {...itemProps}
                  cellMetadata={{
                    ...cellMetadata,
                    position: index,
                  }}
                  onClick={onClick}
                  product={product}
                  displayOptions={DISPLAY_OPTIONS}
                />
              </GridItem>
            ))}
          </Grid>
        )}
      </Box>
    </Flex>
  );
};

const ListView: FC<ProductListProps> = ({
  products,
  onClick,
  children,
  cellMetadata,
  itemProps = {
    variant: "elevated",
    displayOptions: {
      ...DISPLAY_OPTIONS,
      favorite: false,
    },
  },
}) => {
  return products?.map((product, index) => (
    <GridItem className="inherit" key={product.id}>
      <ProductCard
        {...itemProps}
        className="inherit"
        display="list"
        cellMetadata={{
          ...cellMetadata,
          position: index,
        }}
        onClick={onClick}
        product={product}
      >
        {children && isFunction(children) ? children(product) : children}
      </ProductCard>
    </GridItem>
  ));
};

const ProductList: FC<ProductListProps> = ({
  variant = "grid",
  size = "md",
  onViewAll,
  onAfterChange,
  ...props
}) => {
  const disclosure = useDisclosure();
  const [product, setProduct] = useState<DeepPartial<Product>>();
  const handleClick = useCallback(
    (product: DeepPartial<Product>) => {
      setProduct(product);
      disclosure.onOpen();
    },
    [disclosure],
  );
  return (
    <>
      {variant === "grid" && <GridView {...props} onClick={handleClick} />}
      {variant === "carousel" && (
        <CarouselView
          {...props}
          size={size}
          onViewAll={onViewAll}
          onAfterChange={onAfterChange}
          onClick={handleClick}
        />
      )}
      {variant === "list" && <ListView {...props} onClick={handleClick} />}
      <ProductModal {...disclosure} product={product} />
    </>
  );
};

export default ProductList;
