import { gql } from "@apollo/client";
import { FRAGMENT_SHOPPING_SESSION } from "@/documents/fragments/FRAGMENT_SHOPPING_SESSION";

export const MUTATION_CHECKOUT_SHOPPING_SESSION = gql`
  ${FRAGMENT_SHOPPING_SESSION}
  mutation CheckoutShoppingSession(
    $input: CheckoutShoppingSessionInput!
    $restaurantId: Int!
    $includeUpdatedItemsInformation: Boolean = false
  ) {
    checkoutShoppingSession(input: $input) {
      shipments {
        id
        total
        deliveryDate
        deliveryTimeStart
        deliveryTimeEnd
        productsCount
        uniqueId
        orders {
          nodes {
            id
            isRecurringOrder
            editUpTo
            fees {
              title
              type
              amount
            }
          }
        }
        shipmentDelivery {
          id
          status
        }
      }
      shoppingSession {
        ...FragmentShoppingSession
      }
    }
  }
`;
