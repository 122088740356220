import { Badge, Flex, Text } from "@chakra-ui/react";
import { getDollarsDisplayPrice } from "@/utils/price";
import { DisplayOptions } from "@/components/ProductList/ProductCard";
import { FC } from "react";
import { Product } from "@/hooks/products/utils";

type PriceOptions = {
  baseVariant: Product["baseVariant"];
  options: DisplayOptions;
  subTotal?: number;
};

const Price: FC<PriceOptions> = ({ baseVariant, options, subTotal }) => {
  return (
    <>
      <Flex columnGap={1} rowGap={0} alignItems="center" wrap="wrap">
        {options?.price && baseVariant?.price?.basePrice != null && (
          <Text
            as="b"
            fontSize="sm"
            color={baseVariant?.price?.discounted ? "green.500" : "black"}
          >{`${getDollarsDisplayPrice(
            baseVariant?.price?.discounted
              ? baseVariant?.price?.price
              : baseVariant.price.basePrice,
            {
              showZeroPlaceholder: false,
            },
          )}`}</Text>
        )}
        {options?.pricePerUOM &&
          baseVariant?.priceLevelContext?.price != null && (
            <Badge
              fontWeight="bold"
              {...(baseVariant?.price?.discounted
                ? {
                    colorScheme: "green",
                    variant: "solid",
                  }
                : {
                    colorScheme: "blackAlpha",
                    variant: "subtle",
                  })}
            >
              {getDollarsDisplayPrice(baseVariant.priceLevelContext.price, {
                showZeroPlaceholder: false,
              })}
              /{baseVariant?.priceLevelContext?.uom}
            </Badge>
          )}
        {options?.subTotal && subTotal && (
          <Text
            as="b"
            color={baseVariant?.price?.discounted ? "green.500" : "black"}
            fontSize="sm"
          >
            {getDollarsDisplayPrice(subTotal)}
          </Text>
        )}
        {options.price && baseVariant?.price?.discounted && (
          <Text
            fontSize="sm"
            className="line-through"
            as="b"
            color="gray.500"
            aria-label="Original price"
          >{`${getDollarsDisplayPrice(baseVariant?.price.basePrice, {
            showZeroPlaceholder: false,
          })}`}</Text>
        )}
      </Flex>
    </>
  );
};

export default Price;
