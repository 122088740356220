"use client";
import { useReactiveVar, useSuspenseQuery } from "@apollo/client";
import { QUERY_PRODUCT } from "@/documents/QUERY_PRODUCT";
import {
  ProductQuery,
  ProductQueryVariables,
} from "@/documents/__generated__/QUERY_PRODUCT.codegen";
import { sessionUserReactiveVar } from "@/app/UserProvider";

export const useProduct = (sku: string) => {
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const { data } = useSuspenseQuery<ProductQuery, ProductQueryVariables>(
    QUERY_PRODUCT,
    {
      variables: {
        sku,
        restaurantId: sessionUser?.data?.restaurantID,
        includeReplacements: false,
      },
      queryKey: [sku, sessionUser?.data?.authToken],
    },
  );

  if (!data.restaurant?.currentStore?.productBySku) {
    throw new Error("Product not found");
  }

  return data.restaurant.currentStore.productBySku;
};
