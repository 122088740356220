import { Flex, Select, Spinner, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { OrderItem } from "@/hooks/cart/utils";
import { FC, useState } from "react";
import { Product } from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";
import { useShoppingSessionProduct } from "@/hooks/session/useShoppingSessionProduct";
import { useProductEvent } from "@/hooks/analytics/trackers/useProductEvent";
import { EventAction, EventSource } from "@/app/lib/analytics/types";
import { getDollarsPrice } from "@/utils/price";

type QuantitySelectProps = {
  orderItem: OrderItem;
  onQuantityChange: (value: number) => Promise<unknown> | undefined;
  loading: boolean;
  index: number;
  product: DeepPartial<Product>;
  eventSource: EventSource;
};

const MAX_QUANTITY = 101;

const QuantitySelect: FC<QuantitySelectProps> = ({
  orderItem,
  onQuantityChange,
  loading,
  index,
  eventSource,
  ...props
}) => {
  const { control } = useFormContext<{
    orderItems: OrderItem[];
  }>();
  const { product } = useShoppingSessionProduct(props.product);
  const { trackProductPicker } = useProductEvent(product);
  const [updating, setUpdating] = useState<number | null>(null);
  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, ...rest } }) => {
        return (
          <Flex alignSelf="start" alignItems="center" gap={2}>
            <Text color="gray.500" fontSize="xs">
              {orderItem.packageTypeTitle.long}
            </Text>
            <Select
              w="4.5rem"
              iconSize="xs"
              aria-label={`Quantity selector`}
              {...(updating === orderItem.id && {
                icon: <Spinner size="sm" />,
              })}
              isDisabled={updating != null || loading}
              {...rest}
              onBlur={() => {
                onBlur();
                trackProductPicker(EventAction.BLURRED, {
                  source_object: eventSource,
                  quantity: orderItem.quantity,
                  packaging_type: orderItem.packagingType,
                  subtotal: getDollarsPrice(orderItem.subTotal),
                });
              }}
              onChange={async (e) => {
                const quantity = Number(e.target.value);
                onChange(e);
                setUpdating(orderItem.id);
                await onQuantityChange(Number(e.target.value));
                setUpdating(null);

                if (!orderItem.price) {
                  console.error(
                    "Price is not defined for order item",
                    orderItem,
                  );
                  return;
                }

                trackProductPicker(EventAction.CONFIRM, {
                  quantity,
                  packaging_type: orderItem.packagingType,
                  price: getDollarsPrice(orderItem.price),
                  subtotal: getDollarsPrice(orderItem.price * quantity),
                  source_object: eventSource,
                });
              }}
              size="sm"
            >
              {Array.from({ length: MAX_QUANTITY }).map((_, index) => (
                <option key={index} value={index}>
                  {index ? index : "Remove"}
                </option>
              ))}
            </Select>
          </Flex>
        );
      }}
      name={`orderItems.${index}.quantity`}
    />
  );
};

export default QuantitySelect;
