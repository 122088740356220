import { gql } from "@apollo/client";
import { FRAGMENT_SHOPPING_SESSION } from "@/documents/fragments/FRAGMENT_SHOPPING_SESSION";

export const MUTATION_UPDATE_SHOPPING_SESSION = gql`
  ${FRAGMENT_SHOPPING_SESSION}
  mutation UpdateShoppingSession(
    $input: UpdateShoppingSessionInput!
    $restaurantId: Int!
    $includeUpdatedItemsInformation: Boolean = false
  ) {
    updateShoppingSession(input: $input) {
      shoppingSession {
        ...FragmentShoppingSession
      }
    }
  }
`;
