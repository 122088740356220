import { useAppSettings } from "@/hooks/settings/useAppSettings";

const CuisineTypeOptions = () => {
  const { cuisineTypes } = useAppSettings();

  return (
    <>
      {cuisineTypes.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </>
  );
};

export default CuisineTypeOptions;
