import { DisplayOptions } from "@/components/ProductList/ProductCard";
import { OrderItemVariant } from "@/hooks/cart/utils";
import { FC } from "react";
import { Text } from "@chakra-ui/react";
import { Product } from "@/hooks/products/utils";

type ProductNameProps = Product & {
  options: DisplayOptions;
  orderItems?: OrderItemVariant[];
};

const ProductName: FC<ProductNameProps> = ({
  catalogName,
  brand,
  options,
  orderItems,
  baseVariant,
}) => {
  return (
    <>
      <Text fontSize="sm" noOfLines={3}>
        {catalogName}{" "}
        {options?.packagingType && orderItems?.[0] && (
          <Text fontSize="sm" as="span" color="gray.500">
            {orderItems[0].packageTypeTitle?.long}
          </Text>
        )}
      </Text>
      {options?.brand && (
        <Text fontSize="sm" color="gray">
          {brand}
        </Text>
      )}
      {options.quantityDescription && baseVariant?.quantityDescription ? (
        <Text fontSize="sm" color="gray">
          {baseVariant.quantityDescription}
        </Text>
      ) : null}
    </>
  );
};

export default ProductName;
