"use client";
import { FC, useCallback } from "react";
import { useUpdateCart } from "@/hooks/cart/useUpdateCart";
import { SessionUser } from "@/hooks/user/utils";
import { Delivery, OrderItem } from "@/hooks/cart/utils";
import DeliveryList from "@/components/CartDrawer/DeliveryList";
import { EventSource } from "@/app/lib/analytics/types";

type DeliveryContainerProps = {
  user: SessionUser;
  deliveries: Delivery[];
  eventSource: EventSource;
};

const DeliveriesContainer: FC<DeliveryContainerProps> = ({
  deliveries,
  user,
  eventSource,
}) => {
  const { upsert, loading } = useUpdateCart();

  const handleQuantityChange = useCallback(
    (quantity: number, orderItem: OrderItem) => {
      return upsert({
        restaurantId: user.restaurantID,
        orderItemId: orderItem.id,
        quantity,
        packagingType: orderItem.packagingType,
      });
    },
    [upsert, user.restaurantID],
  );

  return (
    <DeliveryList
      onQuantityChange={handleQuantityChange}
      deliveries={deliveries}
      eventSource={eventSource}
      loading={loading}
      outOfDeliveryZone={user.restaurant?.currentAddress?.outOfDeliveryZone}
    />
  );
};

export default DeliveriesContainer;
