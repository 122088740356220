"use client";
import {
  FC,
  RefObject,
  useActionState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Icon,
  Tooltip,
  VStack,
  Avatar,
  Heading,
  useBreakpointValue,
  IconButtonProps,
} from "@chakra-ui/react";
import {
  BiMenu,
  BiLinkExternal,
  BiHome,
  BiBookmarkHeart,
  BiCreditCard,
  BiBody,
  BiRefresh,
  BiHighlight,
  BiFile,
  BiLogOut,
} from "react-icons/bi";
import { logout } from "@/app/actions/auth";
import { useReactiveVar } from "@apollo/client";
import { sessionUserReactiveVar } from "@/app/UserProvider";
import { Link } from "@chakra-ui/next-js";
import { BsBoxSeam } from "react-icons/bs";
import { StoreContext } from "@/app/StoreProvider";
import { usePathname } from "next/navigation";

type HamburgerProps = object;

const Hamburger: FC<HamburgerProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sessionUser = useReactiveVar(sessionUserReactiveVar);
  const store = useContext(StoreContext);
  const pathname = usePathname();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, action, pending] = useActionState(logout, null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const size = useBreakpointValue<IconButtonProps["size"]>(
    {
      base: "sm",
      lg: "md",
    },
    {
      fallback: "lg",
    },
  );

  useEffect(() => {
    onClose();
  }, [pathname]);
  useEffect(() => {
    if (pending) {
      sessionUserReactiveVar({
        loading: true,
        data: sessionUser?.data,
      });
    }
  }, [pending]);
  return (
    <>
      <IconButton
        variant="outline"
        alignSelf="center"
        ref={btnRef}
        onClick={onOpen}
        icon={<Icon as={BiMenu} boxSize="6" />}
        aria-label="Menu"
        size={size}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        size="xs"
        onClose={onClose}
        finalFocusRef={btnRef as RefObject<HTMLButtonElement>}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader />
          <DrawerBody>
            <form action={action} id="logout" />
            <VStack spacing={4} align="stretch">
              {sessionUser?.data && (
                <VStack spacing={2}>
                  <Avatar size="md" name={sessionUser?.data?.name} />
                  <Heading size="sm">{sessionUser?.data?.name}</Heading>
                  <Button
                    aria-label={"Edit profile"}
                    size="md"
                    as={Link}
                    prefetch={false}
                    href="/profile"
                  >
                    Edit profile
                  </Button>
                </VStack>
              )}
              <VStack spacing={0} align="stretch">
                <Button
                  py={2}
                  justifyContent="start"
                  variant="link"
                  as={Link}
                  prefetch={false}
                  color={"blackAlpha.900"}
                  href="/"
                  size={"lg"}
                  leftIcon={<Icon as={BiHome} />}
                >
                  Home
                </Button>
                {sessionUser?.data && (
                  <>
                    <Button
                      size={"lg"}
                      py={2}
                      justifyContent="start"
                      variant="link"
                      color={"blackAlpha.900"}
                      as={Link}
                      href="/lists"
                      prefetch={false}
                      leftIcon={<Icon as={BiBookmarkHeart} />}
                    >
                      Shopping lists
                    </Button>
                    <Button
                      py={2}
                      size={"lg"}
                      justifyContent="start"
                      variant="link"
                      as={Link}
                      prefetch={false}
                      color={"blackAlpha.900"}
                      href="/orders"
                      leftIcon={<Icon as={BsBoxSeam} />}
                    >
                      Orders
                    </Button>
                    {sessionUser.data.isAdmin &&
                      !store?.vendor?.settings?.disabledPlatformPayments && (
                        <Button
                          py={2}
                          justifyContent="start"
                          size={"lg"}
                          variant="link"
                          color={"blackAlpha.900"}
                          as={Link}
                          prefetch={false}
                          href="/payments"
                          leftIcon={<Icon as={BiCreditCard} />}
                        >
                          Payments
                        </Button>
                      )}
                    <Button
                      py={2}
                      justifyContent="start"
                      size={"lg"}
                      variant="link"
                      as={Link}
                      prefetch={false}
                      color={"blackAlpha.900"}
                      href="/staff"
                      leftIcon={<Icon as={BiBody} />}
                    >
                      Staff
                    </Button>
                    <Tooltip hasArrow label="Coming soon" placement="top-start">
                      <Button
                        justifyContent="start"
                        py={2}
                        color={"blackAlpha.900"}
                        size={"lg"}
                        variant="link"
                        isDisabled
                        leftIcon={<Icon as={BiFile} />}
                      >
                        Invoices and credits
                      </Button>
                    </Tooltip>
                    <Tooltip hasArrow label="Coming soon" placement="top-start">
                      <Button
                        justifyContent="start"
                        size={"lg"}
                        py={2}
                        variant="link"
                        color={"blackAlpha.900"}
                        isDisabled
                        leftIcon={<Icon as={BiHighlight} />}
                      >
                        My instructions
                      </Button>
                    </Tooltip>
                    <Button
                      py={2}
                      justifyContent="start"
                      variant="link"
                      as={Link}
                      prefetch={false}
                      href="/recurring-orders"
                      color={"blackAlpha.900"}
                      size={"lg"}
                      leftIcon={<Icon as={BiRefresh} />}
                    >
                      Recurring orders
                    </Button>
                  </>
                )}
              </VStack>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <VStack align="stretch" spacing={4} w="100%">
              <VStack w="100%" align="start">
                <Button
                  size="sm"
                  colorScheme="black"
                  variant="link"
                  as={Link}
                  href="https://kimelo.com/terms"
                  target="_blank"
                  rightIcon={<BiLinkExternal />}
                >
                  Terms & condition
                </Button>
                <Button
                  size="sm"
                  colorScheme="black"
                  variant="link"
                  as={Link}
                  href="https://kimelo.com/privacy"
                  target="_blank"
                  rightIcon={<BiLinkExternal />}
                >
                  Privacy
                </Button>
              </VStack>
              {!sessionUser?.data && (
                <Button
                  colorScheme="midnight_navy"
                  w="100%"
                  as={Link}
                  prefetch={false}
                  href="/signup"
                >
                  Sign up
                </Button>
              )}
              {(sessionUser?.data || pending) && (
                <Button
                  w="100%"
                  isLoading={pending}
                  type="submit"
                  form="logout"
                  leftIcon={<Icon as={BiLogOut} />}
                  aria-label={"Sign out"}
                >
                  Sign out
                </Button>
              )}
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Hamburger;
